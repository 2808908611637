import { TempState } from "../../types/componentTypes"
import { useNavigate, useParams } from "react-router"
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react"
import { Alarm as AlarmType, Building, User } from "../../types/dataTypes"
import Titlebar from "../../components/Titlebar"
import Header from "../../components/Header"
import Search from "../../components/Search"
import OrganizationSelect from "../../components/OrganizationSelect"
import Button from "../../components/Button"
import { getUser, putBuilding } from "../../api/api-ts"
import SectionWrapper from "../../components/SectionWrapper"
import { Link } from "react-router-dom"

type BuildingSettingsParams = {
  building_id: string
}

type BuildingForm = {
  name: string,
  muted_to?: string,
}

export function BuildingSettings({ state, updateState }: { state: TempState, updateState: Function }) {
  const params = useParams<BuildingSettingsParams>()
  const building_id = params.building_id

  const [building, setBuilding] = useState<Building>()
  const [buildingForm, setBuildingForm] = useState<BuildingForm>({ name: "", muted_to: undefined })
  const [user, setUser] = useState<User>()
  const [showConfirm, setShowConfirm] = useState<Boolean>()
  const navigate = useNavigate()

  useEffect(() => {
    if (state.buildings && state.buildings.map(b => b.id).includes(building_id!))
      setBuilding(state.buildings.find(b => b.id == building_id))
  }, [building_id, state])

  useEffect(() => {

    const fetchUser = async (muted_by: string) => {
      let user = await getUser(muted_by)
      setUser(user)
    }

    if (building && (building?.meta !== undefined && building?.meta !== null) && Object.keys(building.meta).includes("muted_by")) {
      fetchUser(building.meta["muted_by"])
    }
  }, [building])

  const onBuildingFormChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setBuildingForm({ ...buildingForm, [event.target.name]: event.target.value })
  }

  async function onSubmitBuildingSettings(e: FormEvent) {
    e.preventDefault()
    let nameInputElemet = document.getElementsByName("name")[0] as HTMLInputElement
    nameInputElemet.value = ""
    if (building && building.id) {

      let muted_to = buildingForm.muted_to ? new Date(buildingForm.muted_to).getTime() : undefined
      let updatedBuilding = await putBuilding(building.id, buildingForm.name, { "muted_to": muted_to })
      if (updatedBuilding) {
        updateBuildingState(updatedBuilding)
      }
    }
  }


  function updateBuildingState(updatedBuilding: Building) {
    setBuilding(updatedBuilding)
    let buildingIndex = state.buildings?.findIndex(b => b.id == updatedBuilding.id)
    if (buildingIndex) {
      state.buildings?.splice(buildingIndex, 1, updatedBuilding)
      updateState(state)
    }
  }

  async function unmuteOnClick() {
    if (building && building.id) {
      let updatedBuilding = await putBuilding(building.id, undefined, { "muted_to": new Date().getTime() })
      if (updatedBuilding) {
        updateBuildingState(updatedBuilding)
      }
    }
  }

  return (
    <div className="tw-h-full">
      {building &&
        <div>
          <div>
            <Titlebar headline={"Building Settings"} />
            <span className={`tw-pt-1 tw-flex-wrap`}>
              <Header headline={building.address} headlineStyles="tw-pt-2">
              </Header>
            </span>
          </div>
          <div className={""}>
            <SectionWrapper styles="tw-m-10 tw-p-6 tw-border tw-bg-gray-200">
                <div className={"tw-grid tw-grid-rows-2"}>
                  <p className={""}>Building settings can be configured here. These settings will apply to all users with access to this building.</p>
                  <Link className={""} to={"/account"}>To modify your personal settings, please click here.</Link>
                </div>
              </SectionWrapper>
            <form onSubmit={onSubmitBuildingSettings}>
              <SectionWrapper styles="tw-m-10 tw-p-6 tw-border">
                <div className={"tw-text-lg tw-font-bold tw-mb-6"}>General Settings</div>
                <div className={"tw-grid tw-grid-cols-6 tw-gap-4"}>
                  <label className={"tw-font-bold tw-text-right tw-mb-0"} htmlFor="name-setting">Building Name: </label>
                  <input className={"tw-text-center tw-border tw-border-gray-200 tw-rounded"} id="name-setting"
                         type="text"
                         name={"name"}
                         placeholder={building.name} onChange={onBuildingFormChange} />
                </div>
              </SectionWrapper>
              <SectionWrapper styles="tw-m-10 tw-p-6 tw-border">
                <div className={"tw-text-lg tw-font-bold tw-mb-6"}>Alarm Settings</div>
                <div className={"tw-grid tw-grid-cols-6 tw-gap-4 tw-content-center"}>
                  <label className={"tw-font-bold tw-text-right"} htmlFor="name-setting">Mute alarms for
                    building</label>
                  <div className={"tw-text-gray-600 tw-pb-2 tw-col-span-5 tw-col-start-2"}>
                    Do not receive alarms for this building until the selected date
                  </div>
                  {(building && building.meta && Object.keys(building.meta).includes("muted_to")) &&
                    new Date(building.meta["muted_to"] * 1000) > new Date() &&
                    (
                      <div className={"tw-grid tw-col-span-6 tw-grid-cols-subgrid tw-grid-cols-6 tw-gap-4"}>
                        <div className={"tw-text-right"}>Muted Until</div>
                        <div
                          className={"tw-col-span-1"}>{new Date(building.meta!["muted_to"] * 1000).toLocaleString()}</div>
                        <div className={"tw-col-span-4"}><Button styles={"tw-col-start-2"} type={"button"}
                                                                 size={"small"}
                                                                 onClick={unmuteOnClick}> Unmute </Button></div>
                        <div className={"tw-text-right"}>Muted At</div>
                        <div
                          className={"tw-col-span-5"}>{new Date(building.meta!["muted_at"] * 1000).toLocaleString()}</div>
                        <div className={"tw-text-right"}>Muted By</div>
                        <div
                          className={"tw-col-span-5"}>{user ? user.name : "Admin (" + building.meta["muted_by"] + ")"}</div>
                      </div>
                    )
                  }
                  <div className={"tw-text-right"}>Mute Until</div>
                  <input className={"tw-border tw-border-gray-200 tw-col-span-1 tw-col-start-2"} id="name-setting"
                         type="datetime-local"
                         name="muted_to" placeholder={building.name} onChange={onBuildingFormChange} />
                </div>
              </SectionWrapper>
              {showConfirm ?
                <div className={"tw-flex tw-flex-row tw-pl-12 tw-gap-4"}>
                  <Button id={"submit-button"} styles={"tw-bg-green-400 tw-border-green-400 hover:tw-bg-primary-off-white"} type={"submit"}
                          size={"medium"}>Confirm</Button>
                  <Button id={"cancel-button"} styles={"tw-bg-red-400 tw-border-red-400 tw-cursor-pointer hover:tw-bg-primary-off-white"}
                          type={"button"}
                          size={"medium"} onClick={(e: MouseEvent) => {
                            e.preventDefault()
                            setShowConfirm(false)
                  }}>Cancel</Button>
                  <span className={"tw-flex tw-flex-row tw-bg-white tw-rounded-full tw-px-6"}>
                    <img
                      src="icon-information.svg"
                      alt="information icon"
                      className="tw-stroke-red-400 tw-items-center tw-w-[20px] tw-mr-2"
                    ></img>
                    <p className={"tw-text-bold tw-content-center tw-text-red-800"}> Notice: This action will modify the settings for this building for all users within your organization.</p>
                    </span>
                </div>
                :
                <div className={"tw-relative"}>
                  <Button id={"save-button"} styles={"tw-absolute tw-left-12 tw-cursor-pointer"} type={"button"}
                          size={"medium"} onClick={(e: MouseEvent) => {
                            e.preventDefault()
                            setShowConfirm(true)
                  }}>Save</Button>
                </div>
              }
            </form>
          </div>
        </div>
      }
    </div>
  )
}