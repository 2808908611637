import React, { useState, useEffect } from 'react';
import InstallationDevice from './components/Device';
import InstallationAsset from './components/Asset';
import {
    postAsset,
    createDeviceDeployment,
    updateOrganizationDevices
} from "../../../api/api-ts"
import {
  getDeviceDeployments,
  getDevices,
  getBuilding
} from "../../../api/api.js"
import {
    Asset,
    DeviceDeployment,
    Device,
    Room
} from "../../../types/dataTypes"
import { useLocation } from 'react-router-dom';
import Button from "../../../components/Button";
import { useNavigate } from 'react-router-dom';
import Modal from '../components/ModalPopup';
import { update } from 'plotly.js';
import { TempState } from '../../../types/componentTypes';

type InstallationDeviceProps = {
  state: TempState
  room?: Room
}

const InstallDevice = ({state, room}: InstallationDeviceProps) => {
  const [includeParent, setIncludeParent] = useState(true);
  const [showModal, setShowModal] = useState<boolean>(false);

  const [subheadline, setSubheadline] = useState('');


  const [deviceSubmitted, setDeviceSubmitted] = useState(false);
  const [assetSubmitted, setAssetSubmitted] = useState(false);
  const [parentAssetSubmitted, setParentAssetSubmitted] = useState(false);
  const [clearInput, setClearInput] = useState(false);
  const [clearParentInput, setClearParentInput] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showSuccessMessageParent, setShowSuccessMessageParent] = useState(false);
  const [successMessage, setSuccessMessage] = useState('Saved!'); 
  const [errorMessage, setErrorMessage] = useState(""); 
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [updateAssets, setUpdateAssets] = useState(false);

  const [newAssetId, setNewAssetId] = useState("")
  
  const location = useLocation();
  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search)
  let selectedRoom = queryParams.get("roomId") || ''; 
  let selectedBuilding = queryParams.get("buildingId") || '';

  useEffect(() => {
    const fetchData = () => {
      const queryParams = new URLSearchParams(location.search)
      let selectedRoom = queryParams.get("roomId") || ''; 
      let selectedBuilding = queryParams.get("buildingId") || '';
      if (selectedRoom) {
        const roomDetails = state.rooms?.find(room => room.id === selectedRoom);
        setSubheadline(`in room: ${roomDetails?.name ?? roomDetails?.nice_name}`)
      }
    }
    fetchData();
  }, [state.rooms])

  const [currentBuildingOrgId, setCurrentBuildingOrgId] = useState<string>('');
  const [devices, setDevices] = useState<Device[]>([]);
  const [deployedDeviceIds, setDeployedDeviceIds] = useState<string[]>([]);


  // @ts-ignore
  const [assetToAdd, setAssetToAdd] = useState<Asset>({
    name: '',
    description: '',
    room_id: selectedRoom,
    model: '',
    asset_category_id: '',
    parent_id: '',
    });

      // @ts-ignore
  const [parentAssetToAdd, setParentAssetToAdd] = useState<Asset>({
    name: '',
    description: '',
    room_id: selectedRoom,
    model: '',
    asset_category_id: '',
    parent_id: '',
    });

    // @ts-ignore
    const [deviceToAdd, setDeviceToAdd] = useState<DeviceDeployment>({
        device_id: '',
        room_id: selectedRoom,
        assets: [],
    });

  //Ensures the error-messages are cleared when input is removed
  useEffect(() => {
    if (!assetToAdd.name && !assetToAdd.description && !assetToAdd.model && !assetToAdd.asset_category_id && !assetToAdd.parent_id) {
      setAssetSubmitted(false)
      setShowErrorMessage(false)

    }
  }, [assetToAdd]);

  useEffect(() => {
    const fetchOrganization = async () => {
        try{
            if(selectedBuilding){
                let currentBuilding = await getBuilding(selectedBuilding);
                setCurrentBuildingOrgId(currentBuilding.organization_id)
            }
        }catch(error){
          console.error("Failed to fetch organization ID for current building: ", error)
        }
      }
    fetchOrganization();
    fetchDeviceDeployments();
  }, [selectedRoom]);

  async function saveDeviceDeployment(assetId: string){
    if (isSubmitting) {
      return; 
    }

    setDeviceSubmitted(true);
    setShowSuccessMessage(false); 
  
    if (!deviceToAdd.device_id) {
      console.error('Required form fields are missing.');
      return; 
    }
    setIsSubmitting(true); 
  
    const selectedDevice = devices.find(device => device.id === deviceToAdd.id);
    const isGateway = selectedDevice?.type?.model === 'KNOT';
  
    const deploymentInfo = {
      device_id: deviceToAdd.device_id,
      room_id: selectedRoom,
      description: "",
      start_time: Date.now() / 1000,
      assets: isGateway ? [] : assetId ? [assetId] : [],
    };

    try {
      await createDeviceDeployment(deploymentInfo);
      const fetchedDevices = await fetchDevices(); 
      const currentDevices = fetchedDevices.filter(device => device.organization_id === currentBuildingOrgId);
      const currentDeviceIds = currentDevices.map(device => device.id);
      if (!currentDeviceIds.includes(deviceToAdd.device_id)) {
          currentDeviceIds.push(deviceToAdd.device_id);

          
      }
      await updateOrganizationDevices(currentBuildingOrgId, currentDeviceIds);
      await fetchDeviceDeployments();
          setShowSuccessMessage(true);
          setShowErrorMessage(false)
          clearDeviceValues()
          setClearInput(!clearInput)
          //setSuccessMessage('Device successfully deployed');
          //setShowSuggestions(false);

    } catch (error) {

      console.error('Error deploying device:', error);
      setErrorMessage("Error deploying device.")
      setShowErrorMessage(true)
    } finally{  
      setDeviceSubmitted(false);
      setIsSubmitting(false);
    };
  }

  async function saveAsset(){
    let assetId = ""

    //Kan valideringen fjernes?
    if (assetToAdd.name != "" && assetToAdd.asset_category_id != "" && assetToAdd.model != "" && assetToAdd.parent_id != "") {
        //Update room id
        setAssetToAdd(prevState => ({
            ...prevState,
            room_id: selectedRoom
        }));
  
      const result: Asset = await postAsset(assetToAdd)
      
      assetId = result.id

      clearAssetValues() //Clears actual values
      setAssetSubmitted(false)
      setShowErrorMessage(false)
      return assetId
    } else{
      setErrorMessage("Error saving asset")
      setShowErrorMessage(true)
      console.error("Error sending data - Input fields are missing")
    }
  }

  async function saveParentAsset(){
    setParentAssetSubmitted(true)
    if (parentAssetToAdd.name != "" && parentAssetToAdd.asset_category_id != "" && parentAssetToAdd.model != "") {
        //Update room id
        setParentAssetToAdd(prevState => ({
          ...prevState,
          room_id: selectedRoom
      }));
  
      await postAsset(parentAssetToAdd)

      setShowSuccessMessageParent(true)  //Shows success-message
      clearParentValues() //Clears actual values

      setUpdateAssets(!updateAssets)

      setParentAssetSubmitted(false)
    } else{
      console.error("Error sending data - Input fields are missing")
      setErrorMessage("Error saving asset")
      setShowErrorMessage(true)
    }
  }

  async function handleSave(event: React.SyntheticEvent) {
    //If asset is empty - save device
    if(assetToAdd.name == "" && assetToAdd.asset_category_id == "" && assetToAdd.model == "" && assetToAdd.parent_id == ""){
      saveDeviceDeployment("")
    //if one field in asset is filled in - show error message
    } else if(assetToAdd.name == "" || assetToAdd.asset_category_id == "" || assetToAdd.model == "" || assetToAdd.parent_id == ""){
      setAssetSubmitted(true)
      setErrorMessage("Fill in missing fields, or remove fields if you do not want to save the device to a system.")
      setShowErrorMessage(true)
    //Save both asset and devicedeployment
    } else{
      const assetId: string = String(await saveAsset())
      saveDeviceDeployment(assetId)
      setNewAssetId(assetId)
    }
  }

  const clearParentValues = () =>{
    //@ts-ignore
    setParentAssetToAdd({
      name: '',
      description: '',
      room_id: selectedRoom,
      model: '',
      asset_category_id: '',
      parent_id: '',
    });
  }

    const clearAssetValues = () =>{
      //@ts-ignore
      setAssetToAdd({
          name: '',
          description: '',
          room_id: selectedRoom,
          model: '',
          asset_category_id: '',
          parent_id: '',
        });
    }

  const clearDeviceValues = () =>{
      //@ts-ignore
      setDeviceToAdd({
        device_id: '',
        room_id: selectedRoom,
        assets: []
    });
  }

  const fetchDevices = async (): Promise<Device[]> => {
    try {
      const allDevices = await getDevices();
      setDevices(allDevices);
      return allDevices; 
    } catch (error) {
      console.error('Failed to fetch devices:', error);
      return []; 
    }
};

const fetchDeviceDeployments = async () => {
    try {
      const allDeployments: DeviceDeployment[] = await getDeviceDeployments([], [], [], [], [], []);
      const deployedIds = allDeployments.map(deployment => deployment.device_id);
      setDeployedDeviceIds(deployedIds);
    } catch (error) {
      console.error('Failed to fetch device deployments:', error);
    }
  };
  
  const navigateToOverview = () =>{
    if(newAssetId) {
      navigate(`/installation/?assetId=${newAssetId}`)
    } else{
      navigate(`/installation/?roomId=${selectedRoom}`);
    }
  }
  
  const toggleModal = () => {
    setShowModal(!showModal);
    setParentAssetSubmitted(false)
    setShowSuccessMessageParent(false)
  };

  return (
    <div>
      <InstallationAsset includeParent={includeParent} formData={assetToAdd} setFormData={setAssetToAdd} submitted={assetSubmitted} toggleModal={toggleModal} updateAssets={updateAssets} subHeadline={`${subheadline}`}></InstallationAsset>

      <InstallationDevice formData={deviceToAdd} setFormData={setDeviceToAdd} clearInput={clearInput} submitted={deviceSubmitted} deployedDeviceIds={deployedDeviceIds}></InstallationDevice>      
      <div className='tw-w-full tw-flex tw-pb-4'>
        <div className='tw-w-1/2 tw-flex tw-px-8'>
          <Button onClick={handleSave} type={'button'} size={'medium'}>Sumbit</Button>
        </div>
        <div className='tw-w-1/2 tw-flex tw-justify-end tw-px-8'>
          <Button type={"button"} size={'medium'} onClick={navigateToOverview} styles='margin-right: 50px'>To overview</Button>
        </div>
      </div>          
      {showSuccessMessage && (
          <div style={{ color: 'green', marginTop: '10px' }}>
            {successMessage}
          </div>
        )}
      {showErrorMessage && (
          <div style={{ color: 'red', marginTop: '10px' }}>
            {errorMessage}
          </div>
        )}

      <Modal open={showModal} onClose={toggleModal} headline={'New system'}>
          <div>
          <InstallationAsset includeParent={false} formData={parentAssetToAdd} setFormData={setParentAssetToAdd} submitted={parentAssetSubmitted} toggleModal={toggleModal} updateAssets={updateAssets}></InstallationAsset>
          
          <Button onClick={saveParentAsset} type={'button'} size={undefined}>Save</Button>
          <Button onClick={toggleModal} type={'button'} size={undefined}>Close</Button>
          {showSuccessMessageParent && (
          <div style={{ color: 'green', marginTop: '10' }}>
            System is saved
          </div>
        )}
          </div>
      </Modal>
    </div>
  );
};

export default InstallDevice;