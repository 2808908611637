import React, { ChangeEvent, useState, useEffect } from 'react';

type TextInputProps = {
    id: string;
    label?: string;
    value: string | undefined;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void | undefined;
    placeholder?: string;
    validate?: (value: string) => string | undefined;
    submitted?: boolean | undefined;
};

const TextInput: React.FC<TextInputProps> = ({ id, label, value, onChange, placeholder, validate, submitted }) => {
    const [error, setError] = useState<string | undefined>(undefined);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value: inputValue } = e.target;
        onChange(e);
        if (validate) {
            const errorMessage = validate(inputValue);
            setError(errorMessage);
        }
    };

    useEffect(() => {
        if (submitted) {
            setError(validate ? validate(value ? value : "") : undefined);
        }
    }, [submitted, validate, value]);

    return (
        <div className='tw-w-full tw-px-4 tw-py-0 tw-flex tw-flex-col'>
            <label htmlFor={id} className='tw-mb-0 tw-ml-2 tw-text-md tw-font-bold tw-text-gray-700'>{label}</label>  
            <input
                type='text'
                id={id}
                value={value || ''}
                onChange={handleChange}
                placeholder={error && submitted ? error : placeholder}
                className={`tw-w-full sm:tw-w-full md:tw-w-1/3 lg:tw-w-1/3 tw-p-4 tw-text-base ${error && submitted ? 'tw-text-red-500' : 'tw-text-gray-700'} tw-bg-white 
                tw-border ${error && submitted ? 'tw-border-red-500' : 'tw-border-gray-300'} tw-rounded-full tw-shadow-sm 
                tw-placeholder-gray-400 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-gray-500 focus:tw-border-transparent tw-mb-0`}
            />
            <br />
        </div>
    );
};

export default TextInput;
