import ProtectedRoute from "../ProtectedRoute"
import React from "react"
import { Routes, Route } from "react-router-dom"
import { TempState } from "../../types/componentTypes"

import InstallationRoom from "../Installation/Room"
import InstallationBuilding from "../Installation/Building"
import InstallationOverview from "../Installation/Overview"
import InstallDevice from "../Installation/Device"

export default function InstallationRoute(state: TempState,  updateState: () => Promise<void>) {
  
    return (<>
        <Route
            path="/installation/room"
            element={
              <ProtectedRoute key={14}
                outlet={<InstallationRoom state={state}/>}
              />
            }
          />
          <Route
            path="/installation/room/:room_id"
            element={
              <ProtectedRoute key={15}
                outlet={<InstallationRoom state={state}/>}
              />
            }
          />
          <Route
            path="/installation"
            element={
              <ProtectedRoute key={16}
                outlet={<InstallationOverview state={state} updateState={updateState}/>}
              />
            }
          />
          <Route
            path="/installation/building"
            element={
              <ProtectedRoute key={17}
                outlet={<InstallationBuilding state={state}/>}
              />
            }
          />
          <Route
            path="/installation/building/:building_id"
            element={
              <ProtectedRoute key={18}
                outlet={<InstallationBuilding state={state}/>}
              />
            }
          />
          <Route
            path="/installation/device"
            element={
              <ProtectedRoute key={19}
                outlet={<InstallDevice state={state}/>}
              />
            }
          />
    </>)
    
}
        