import {Alarm} from "../../types/dataTypes";

export const formatDate = (date: Date) => {
  const pad = (a: number) => {
    if (a >= 10) {
      return `${a}`
    } else {
      return `0${a}`
    }
  }
 return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())} ${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`
}

export const formatAlarmText = (alarm: Alarm): string => {
  return `Alarm type: ${alarm.alarm_type}<br>${alarm.description}`
}

export const createPreviousAlarmXYAndText = (alarm: Alarm, numberOfPoints: number, yLowerRange: number, yUpperRange: number) => {
  // hack to make alarm lines "hoverable" with text
  const x: string[] = []
  const y: number[] = []
  let text: string[] = []
  for (let i = 0; i < numberOfPoints; i++) {
    x.push(formatDate(new Date(alarm.timestamp * 1000)));
    y.push(yLowerRange + (yUpperRange - yLowerRange) / numberOfPoints * i)
    text.push(formatAlarmText(alarm))

  }
  return {
    x, y, text
  }
}