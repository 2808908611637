import {
  Alarm,
  Asset,
  Building,
  Device,
  DeviceDeployment,
  Room
} from "../../types/dataTypes";
import {ConditionRow} from "../../types/componentTypes";

const statusGravity: { [key: string]: number } = {
  "UNKNOWN": 0,
  "OK": 1,
  "WARNING": 2,
  "CRITICAL": 3
}

function statusFromAlarm(d: Device, alarms?: Alarm[], device_deployments?: DeviceDeployment[]) {
  device_deployments?.sort((a,b) => a.start_time - b.start_time)

  let newest_deployment: DeviceDeployment | undefined

  if (device_deployments) {
     newest_deployment = device_deployments[0]
  } else {
    newest_deployment = undefined
  }

  if (alarms !== undefined && alarms.filter(a => a.alarm_type === "A" && !a.resolved).length > 0) {
    return {
      status: "CRITICAL", status_text: "CRITICAL", device: d,
      averaged_anomalies: [],
      continuous_anomalies: []
    }
  } else if (alarms !== undefined && alarms.filter(a => a.alarm_type === "B" && !a.resolved).length > 0) {
    return {
      status: "WARNING", status_text: "WARNING", device: d,
      averaged_anomalies: [],
      continuous_anomalies: []
    }
  } else if (newest_deployment == undefined || newest_deployment.start_time > Math.floor((new Date().getTime() - 30*24*60*60*1000)/1000)){
    return {
      status: "UNKNOWN", status_text: "UNKNOWN", device: d,
      averaged_anomalies: [],
      continuous_anomalies: []
    }
  } else {
    return {
      status: "OK", status_text: "No anomalies",
      device: d,
      averaged_anomalies: [],
      continuous_anomalies: []
    }
  }
}

export function createConditionRowsForOrg(buildings?: Building[],
                                          rooms?: Room[],
                                          assets?: Asset[],
                                          deployments?: DeviceDeployment[],
                                          devices?: Device[],
                                          alarms?: Alarm[]
) {
  let cmRows: ConditionRow[] = []
  buildings?.forEach(b => {delete b.status})
  rooms?.forEach(r => {delete r.status})

  assets?.forEach(asset => {
    let room = rooms?.find(room => room.id === asset.room_id)
    let deviceDeployments = deployments?.filter(deployment => deployment.assets.map(a => a.id).includes(asset.id)
      && (deployment.end_time === null || deployment.end_time === undefined)) //XXX: This hides old deployments.
    let building = buildings?.find(b => b.id === room?.building_id)
    let sensors = devices?.filter(d => (deviceDeployments?.map(dd => dd.device_id) ?? []).includes(d.id))
    let device_ids = sensors?.map(d => d.id)
    let device_alarms = alarms?.filter(a => device_ids && device_ids.includes(a.device_id))
    //XXX: This disregards alarms older than 90 days.
    sensors?.forEach(d => d.deviceStatus =
      statusFromAlarm(d, alarms?.filter(a => a.device_id === d.id
        && a.timestamp > (new Date().getTime()/1000 - 90*24*60*60)
        && !a.resolved), deviceDeployments) ?? {
      status: "UNKNOWN",
      status_text: "No data",
      device: d,
      averaged_anomalies: [],
      continuous_anomalies: []
    })

    if (room && (deviceDeployments?.length ?? 0 > 0) && building && (sensors?.length ?? 0 > 0) && device_ids) {

      /** Set statuses up from sensor status **/

      let gravestSensorStatus = sensors!.reduce((prev, next) => {
        return statusGravity[next.deviceStatus!.status] > statusGravity[prev.deviceStatus!.status]
          ? next : prev
      })

      if (building.status) {
        if (statusGravity[building.status] < statusGravity[gravestSensorStatus.deviceStatus!.status]) {
          building.status = gravestSensorStatus.deviceStatus!.status
          building.status_text = gravestSensorStatus.deviceStatus!.status_text
        }
      } else {
        building.status = gravestSensorStatus.deviceStatus!.status
        building.status_text = gravestSensorStatus.deviceStatus!.status_text
      }

      if (room.status) {
        if (statusGravity[room.status] < statusGravity[gravestSensorStatus.deviceStatus!.status]) {
          room.status = gravestSensorStatus.deviceStatus!.status
          room.status_text = gravestSensorStatus.deviceStatus!.status_text
        }
      } else {
        room.status = gravestSensorStatus.deviceStatus!.status
        room.status_text = gravestSensorStatus.deviceStatus!.status_text
      }

      cmRows.push({
        alarms: alarms ?? [],
        asset: asset,
        building: building,
        room: room,
        deviceDeployments: deviceDeployments!,
        devices: sensors!,
        device_ids: device_ids,
        status: gravestSensorStatus?.deviceStatus?.status ?? "UNKNOWN",
        status_text: gravestSensorStatus?.deviceStatus?.status_text ?? "No data"
      })
    }

  })
  return cmRows
}