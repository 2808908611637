import React, { useState } from 'react';
//import './hintButton.css';

interface HintProps {
  text: string;
}

const HintButton: React.FC<HintProps> = ({ text }) => {
  const [showHint, setShowHint] = useState(false);

  const toggleHint = () => {
    setShowHint(!showHint);
  };

  return (
    <span className="tw-relative tw-px-2">
      <button className="tw-border-2 tw-border-sky-200 tw-rounded-full tw-inline-flex tw-justify-center tw-items-center tw-w-6 tw-h-6 tw-text-sky-200" onClick={toggleHint}>i</button>
      {showHint && <div className="tw-absolute tw-bg-white tw-border-2 tw-border-stone-300 p-1 tw-ml-5 tw-rounded-lg tw-z-50">{text}</div>}
    </span>
    
  );
};

export default HintButton;