import React, { useEffect, useRef, useState } from 'react'
import { getImage, uploadImage } from '../../../api/api-ts'
import Button from '../../../components/Button'
interface ImageFile {
    url: string,
    name: string
}

interface ProgressInfo {
    percentage: number,
    fileName: string
}

// REMOVE COMMENTS WHEN REMOVING TEMP SOLUTION
const ImageUpload: React.FC = () => {
    const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null)
    const [imagePreviews, setImagePreviews] = useState<Array<string>>([])
    const [progressInfos, setProgressInfos] = useState<Array<ProgressInfo>>([])
    const [message, setMessage] = useState<Array<string>>([])
    const [imageInfos, setImageInfos] = useState<Array<ImageFile>>([])
    const progressInfosRef = useRef<any>(null)

    const selectImages = (event: React.ChangeEvent<HTMLInputElement>) => {
        let images: Array<string> = []
        let files = event.target.files

        if (files) {
            for (let i = 0; i < files.length; i++) {
                images.push(URL.createObjectURL(files[i]))
            }

            setSelectedFiles(files)
            setImagePreviews(images)
            setProgressInfos([])
            setMessage([])
        }
    }

    const uploadImages = () => {
        if (selectedFiles != null) {
            const files = Array.from(selectedFiles)

            let _progressInfos = files.map((file) => ({
                percentage: 0,
                fileName: file.name
            }))

            progressInfosRef.current = _progressInfos
            
            const uploadPromises = files.map((file, i) => tempupload(/*i, */file))

            Promise.all(uploadPromises)
                .then(() => getImage())
                .then((images) => {
                    setImageInfos(images.data)
                })
            setMessage([])
        }
    }

    const upload = (idx: number, file: File) => {
        let _progressInfos = [...progressInfosRef.current]
        // Original er uten: : { loaded: number; total: number }
        return uploadImage(file, (event: { loaded: number; total: number }) => {
            _progressInfos[idx].percentage = Math.round(
                (100*event.loaded) / event.total
            )
            setProgressInfos(_progressInfos)
        })
        .then(() => {
            setMessage((prevMessage) => [
                ...prevMessage,
                file.name + ": Succesful!"
            ])
        })
        .catch((err: any) => {
            _progressInfos[idx].percentage = 0
            setProgressInfos(_progressInfos)

            let msg = file.name + ": Failed!"
            if (err.repsonse && err.response.data && err.response.data.message) {
                msg += " " + err.response.data.message
            }

            setMessage((prevMessage) => [
                ...prevMessage,
                msg
            ])
        })
    }

    useEffect (() => {
        getImage().then((response) => {
            setImageInfos(response.data)
        })
    }, [])

    // REMOVE COMMENTS WHEN REMOVING TEMP SOLUTION
    //Temporary local upload
    const tempupload = (file: File): Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader()

            reader.onload = (event) => {
                // @ts-ignore
                const imageDataUrl = event.target.result as string
                localStorage.setItem('tempImage', imageDataUrl)
                resolve(imageDataUrl)
                console.log(imageDataUrl)
            }

            reader.onerror = (error) => {
                reject(error)
            }

            reader.readAsDataURL(file)
        })
    }


  return (
    
    <div>
        <div><p className='tw-mb-0 tw-ml-2 tw-px-2 tw-text-md tw-font-bold tw-text-gray-700'>Image of the room</p></div>
        {progressInfos && 
        progressInfos.length > 0 &&
        progressInfos.map((progressInfo: ProgressInfo, index: number) => (
            <div className='mb-2' key={index}>
                <span>{progressInfo.fileName}</span>
                <div className='progress'>
                    <div
                        className="progress-bar progress-bar-info"
                        role="progressbar"
                        aria-valuenow={progressInfo.percentage}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ width: progressInfo.percentage + "%" }}
                    >
                        {progressInfo.percentage}%
                    </div>
                </div>
            </div>
        ))}

<div className='sm:tw-w-full md:tw-w-1/4 lg:tw-w-1/4 tw-px-4 tw-py-2 tw-flex tw-bg-gray-100 tw-rounded-full tw-relative tw-max-w-lg tw-mx-1  tw-my-0'>
    <label htmlFor='file-upload' className='tw-flex tw-flex-1 tw-justify-center tw-cursor-pointer 
    tw-text-center tw-bg-blue-500 hover:tw-bg-blue-600 tw-text-white tw-rounded-l-full tw-py-2 
    tw-px-4 tw-transition-colors tw-border tw-border-r-0 tw-border-transparent tw-align-middle tw-h-full'>
        Select Files
        <input
            id='file-upload'
            type='file'
            multiple
            accept='image/*'
            onChange={selectImages}
            className='tw-sr-only'
        />
    </label>
    <button
        disabled={!selectedFiles}
        onClick={uploadImages}
        className={`tw-flex tw-flex-1 tw-justify-center tw-text-center tw-bg-gray-300 
        ${selectedFiles ? 'hover:tw-bg-blue-500 tw-text-white' : 'tw-text-gray-500'} 
        tw-rounded-r-full tw-py-2 tw-px-4 tw-transition-colors tw-border tw-border-l-0 tw-border-transparent tw-align-middle tw-h-full`}
        type='button'
    >
        Upload
    </button>
</div>


    

        {imagePreviews && (
            <div>
                {imagePreviews.map((img, i) => {
                    return (
                        <img className='preview' src={img} alt={'image-' + i} key={i}></img>
                    )
                })}
            </div>
        )}

        {imageInfos.length > 0 && (
            <div className='card mt-3'>
                <div className='card-header'>List of Images</div>
                <ul className='list-group list-group-flush'>
                    {imageInfos.map((img, index) => (
                        <li className='list-group-item' key={index}>
                            <p>
                                <a href='img.url'>{img.name}</a>
                            </p>
                            <img src={img.url} alt={img.name} height='80px'></img>
                        </li>
                    ))}
                </ul>
            </div>
        )}
    </div>
  )
}

export default ImageUpload