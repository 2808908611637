import React from "react";
import { useNavigate, Link } from "react-router-dom";

type ButtonProps = {
    children: React.ReactNode;
    onClick?: Function;
    type: "button" | "submit" | "reset";
    variant?: "primary" | "secondary" | "third" | undefined;
    size: "small" | "medium" | "large" | undefined;
    disabled?: boolean;
    styles?: string;
    id?: string;
    to?: string;
};

type LinkButtonProps = {
    children: React.ReactNode;
    onClick?: Function;
    variant?: "primary" | "secondary" | "third" | undefined;
    size: "small" | "medium" | "large" | undefined;
    styles?: string;
    id?: string;
    to: string;
};

const defaultButtonStyling = "tw-rounded-full tw-inline-block tw-whitespace-nowrap tw-text-center hover:tw-bg-transparent tw-h-11";
const primaryButtonStyling = "tw-bg-primary-main-black tw-text-typography-light hover:tw-text-typography-dark tw-border-primary-main-black";
const secondaryButtonStyling = "tw-bg-primary-off-white tw-text-secondary-darker-off-white tw-border-secondary-darker-off-white";

export default function Button({
    children,
    onClick,
    type,
    disabled,
    variant = "primary",
    size = "medium",
    styles,
    id,
    to,
}: ButtonProps) {
    const navigate = useNavigate();

    const buttonStyling = (() => {
        if (variant === "third") {
            if (disabled) {
                return secondaryButtonStyling;
            } else {
                return primaryButtonStyling;
            }
        } else if (variant === "primary") {
            return primaryButtonStyling;
        } else {
            return secondaryButtonStyling;
        }
    })();

    return (
        <button
            id={id || ""}
            className={`${defaultButtonStyling} ${buttonStyling} ${
                size === "small" ? "tw-h-9 tw-py-1 tw-px-3 tw-border" : ""
            }
            ${size === "medium" ? "tw-h-11 tw-py-2 tw-px-7 tw-border-2" : ""}
            ${size === "large" ? "tw-h-14 tw-py-3 tw-px-10 tw-border-2" : ""}
            ${styles}`}
            onClick={(event) => {
                onClick && onClick(event);
                to && navigate(to);
            }}
            type={type}
            disabled={disabled}
        >
            <div className="tw-flex tw-justify-evenly tw-flex-nowrap tw-shrink-0 tw-align-middle">{children}</div>
        </button>
    );
}

export function LinkButton({
    children,
    onClick,
    variant = "primary",
    size = "medium",
    styles,
    id,
    to,
}: LinkButtonProps) {
    return (
        <Link
            id={id || ""}
            className={`${defaultButtonStyling} tw-w-fit tw-no-underline hover:tw-no-underline visited:tw-no-underline active:tw-no-underline tw-select-none ${variant === "primary" ? primaryButtonStyling : `${secondaryButtonStyling} hover:tw-text-secondary-darker-off-white`} ${size === "small" ? "tw-h-9 tw-py-1 tw-px-5 tw-border" : ""} ${size === "medium" ? "tw-h-11 tw-py-2 tw-px-7 tw-border-2" : ""} ${size === "large" ? "tw-h-14 tw-py-3 tw-px-10 tw-border-2" : ""} ${styles}`}
            to={to}
        >
            <div className="tw-flex tw-justify-evenly">{children}</div>
        </Link>
    );
}
