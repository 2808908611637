import { TempState } from "../../types/componentTypes"
import Titlebar from "../../components/Titlebar"
import Header from "../../components/Header"
import Search from "../../components/Search"
import OrganizationSelect from "../../components/OrganizationSelect"
import React, { useEffect, useState } from "react"
import { availableOrganizations } from "../../functions/organizationHelpers"
import { Building, Organization } from "../../types/dataTypes"
import TrafficLight from "../../components/TrafficLight"
import DropdownWrapper from "../../components/DropdownWrapper"
import { BuildingOffice2Icon } from "@heroicons/react/20/solid"
import GenerticTable from "../../components/GenericTable"
import { useLocation, useNavigate } from "react-router"
import { Link } from "react-router-dom"


export const Buildings = ({ state }: { state: TempState }) => {

  const location = useLocation()
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search)

  const [selectedOrg, setSelectedOrg] = useState<Organization>()
  const [availableBuildings, setAvailableBuildings] = useState<Building[]>([])

  const availableOrgs = availableOrganizations(state.organizations, state.buildings)



  useEffect(() => {
    let orgId = queryParams.get("orgId")
    let org = availableOrgs.find(o => o.id === orgId)
    if (org === undefined && availableOrgs.length > 0) {
      queryParams.set("orgId", availableOrgs[0].id)
      navigate({search: queryParams.toString()})
    }
    else {
      setSelectedOrg(org)
    }
  }, [state, queryParams])

  useEffect(() => {
    setAvailableBuildings(state.buildings?.filter(b => b.organization_id === selectedOrg?.id) ?? [])
  }, [selectedOrg])


  function selectOrg(org: Organization) {
    setSelectedOrg(org)
    queryParams.set("orgId", org.id)
    navigate({search: queryParams.toString()})
  }

  return (
    <div className="tw-h-full">
      <div>
        <Titlebar headline="Buildings" />
        <span className={`tw-pt-1 tw-flex-wrap`}>
          <Header headline="Buildings In Organisation" headlineStyles="tw-pt-2">
            <Search handleSearch={() => {
            }}></Search>
            <OrganizationSelect
              availableOrganizations={availableOrgs}
              selected={selectedOrg}
              setSelected={selectOrg}
              className="tw-flex-wrap tw-mt-2 lg:tw-absolute lg:tw-right-10" />
          </Header>
        </span>
      </div>
      <div>
        {availableOrgs.length > 0 && (<DropdownWrapper
          key={"dropdown-buildings"}
          title={"Buildings in " + selectedOrg?.name}
          titleIcon={<BuildingOffice2Icon className="tw-h-5 tw-w-5 tw-text-gray-400"
                                          aria-hidden="true" />}
          defaultOpen={true}
        >
          <GenerticTable id={"building-table"}
                         columns={["Address", "Name", "Settings"]}
                         items={availableBuildings}
                         itemToColumns={(building: Building) => [
                           (
                             <div className={"tw-flex"}>{building.address}{(building.meta && Object.keys(building.meta).includes("muted_to")
                                 && new Date(building.meta["muted_to"] * 1000) > new Date()) &&
                               <img title={"Building muted. Click to go to settings. "} alt={"muted-building"}
                                    src={"muted-alarm-bell.svg"} className={"tw-h-7 tw-ml-4"}
                                    onClick={() => navigate(`/buildings/${building?.id}/settings`)} />}</div>),
                           (<div>{building.name}</div>),
                           (<Link to={`/buildings/${building.id}/settings`}><img src="icon-settings.svg" alt="arrow icon"/></Link>),
                         ]}
                         itemToColumnStyle={(building: Building) => [
                           "tw-font-bold", "", "tw-cursor-pointer",
                         ]}
          />
        </DropdownWrapper>)}
      </div>
    </div>
  )
}