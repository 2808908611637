import TextInput from "../../components/TextInput";
import SelectInput from "../../components/SelectInput";
import Titlebar from '../../../../components/Titlebar';
import React, { useState, useEffect, ChangeEvent } from "react";
import GenericSelect from "../../../../components/GenericSelect";
import {
    getAssetCategories,
} from "../../../../api/api-ts"
import {
  getAssets,
  getRoom
} from "../../../../api/api.js"
import {
    Asset,
    AssetCategory,
} from "../../../../types/dataTypes"
import { useLocation } from 'react-router-dom';
import Button from "../../../../components/Button";
import HintButton from "../../components/HintButton";

interface Props {
  includeParent: boolean;
  formData: Asset;
  setFormData: React.Dispatch<React.SetStateAction<Asset>>;
  submitted: boolean;
  toggleModal: () => void;
  updateAssets: boolean
  subHeadline?: string
}

const InstallationAsset: React.FC<Props> = ({ includeParent, formData, setFormData, submitted, toggleModal, updateAssets, subHeadline}) => {
    const location = useLocation();
    const [assetCategories, setAssetCategories] = useState<AssetCategory[]>([]);
    let [assets, setAssets] = useState<Asset[]>([]);
    const queryParams = new URLSearchParams(location.search)
    let selectedRoom = queryParams.get("roomId") || ''; 
    let selectedBuilding = queryParams.get("buildingId") || ''; 
    useEffect(() => {
      const fetchData = async () => {
        const updatedAssets = await getAssets([], [], [selectedRoom])
        setAssets(updatedAssets)
        
      }
      fetchData()
    }, [updateAssets]);

    //Retrieves assets and assetCategories
    useEffect(() => {
      const fetchData = async () => {
          try {
            let assetCategories: AssetCategory[] = await getAssetCategories()
            setAssetCategories(assetCategories);
          } catch (error) {
            console.error("Error fetching asset categories", error);
          }
          try {
            if(selectedBuilding){
              assets = await getAssets([], [], [selectedRoom])
              setAssets(assets);
            }
          } catch (error) {
            setAssets([])
            console.error("Error fetching assets", error);
          }
        };
        fetchData();
    }, [selectedBuilding, selectedRoom]);
  
    const handleInputChangeName = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, name: e.target.value });
    }

    const handleInputChangeDescription = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, description: e.target.value });
    }

    const handleCategoryChange = (selectedCategory: AssetCategory | undefined) => {
      if(selectedCategory && selectedCategory.id){
        setFormData({ ...formData, asset_category_id: selectedCategory.id});
      }
    };

    const handleInputChangeModelType= (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, model: e.target.value });
    }

    const handleParentChange = (selectedAsset: Asset | undefined) => {
      // Check if the selected asset is defined and has an id property
      if (selectedAsset && selectedAsset.id) {
          setFormData({
              ...formData,
              parent_id: selectedAsset.id, // Update the parent_id with the id from the selected asset
          });
      }
  };

    return (
      <div>
      <form  
      onSubmit={e => e.preventDefault()}
      >
      {includeParent && (
        <div>
          <Titlebar headline='Device deployment' subHeadline={`${subHeadline}`}/>
          <div className="tw-px-6 tw-pt-4 tw-pb-2">
            <h1 className="tw-items-center tw-flex tw-justify-center tw-text-17 tw-font-bold tw-px-6">System
            <HintButton text="Example: A pump can belong to a ventilation system. Select the ventilation system in the list, or create a new by pushing the button."/>
            </h1>
          </div>
          <h1 className={`tw-flex tw-text-md tw-text-gray-700 tw-px-6`}>
          Is this a system with several components?
          </h1>
          <div className="tw-flex tw-w-full tw-px-2 tw-py-2">
              <div className="tw-w-1/2 tw-flex tw-items-center tw-justify-center">
                <GenericSelect
                  title=""
                  selected={assets.find(parent => parent.id === formData.parent_id)} // Match the selected asset by id
                  setSelected={handleParentChange}
                  mapAvailableToSelectable={function(p: Asset) {
                    return { id: p.id, name: p.name}
                  }}
                  available={assets}
                  border="yes" // Or adjust based on your styling requirements
                  className="tw-w-full tw-pr-2"
                  selectedName="Select System" // This is the name for the default selection
                  />
              </div>
              <div className="tw-flex tw-items-center tw-justify-center">
                  or
              </div>
              <div className="tw-w-1/2 tw-flex tw-items-center tw-justify-center tw-px-2">
                  <Button 
                  onClick={toggleModal} 
                  type={"button"} 
                  size={"small"}
                  variant={"third"}
                  styles="tw-w-full"
                  >Create new
                  </Button>
              </div>
          </div>
        </div>
      )}
      <div className="tw-pt-2 tw-pb-2">
        <div className="tw-px-6 tw-pt-2 tw-pb-2">
          <h1 className="tw-items-center tw-flex tw-justify-center tw-text-17 tw-font-bold tw-px-6">Component
            <HintButton text="A system defines the object you are installing the device on. It can for example be a pump, a fan, a recycler etc." />
          </h1>
        </div>
        <h1 className={`tw-flex tw-text-md tw-text-gray-700 tw-px-6`}>
          What is the name of the componnent this device is installed on?*
        </h1>
        <div className="tw-flex tw-w-full tw-pt-2">
          <TextInput 
            id="name" 
            label=""
            placeholder="E.g. 360.001"
            value={formData.name} 
            onChange={handleInputChangeName} 
            validate={(name) => (name.trim() ? undefined : 'Name is required')}
            submitted={submitted}
            />
        </div>
        <h1 className={`tw-flex tw-text-md tw-text-gray-700 tw-px-6`}>
            What category does this component belong to?*
        </h1>
        <div className="tw-flex tw-w-full tw-px-2 tw-py-2">
            <div className="tw-w-full tw-flex tw-items-center tw-justify-center">
              <GenericSelect
                title=""
                selected={assetCategories.find(category => category.id === formData.asset_category_id)} // Match the selected asset by id
                setSelected={handleCategoryChange}
                mapAvailableToSelectable={function(a: AssetCategory) {
                  return { id: a.id, name: a.name}
                }}
                available={assetCategories}
                border="yes" // Or adjust based on your styling requirements
                className="tw-w-full tw-pr-2"
                selectedName="Select category" // This is the name for the default selection
                />
            </div>
        </div>
        <h1 className={`tw-flex tw-text-md tw-text-gray-700 tw-px-6 tw-pt-4`}>
          What is the model type of the component?*
        </h1>
        <div className="tw-flex tw-w-full tw-pt-2">
          <TextInput 
            id="modelType" 
            label=""
            placeholder="E.g. Grundfos, GOLD"
            value={formData.model} 
            onChange={handleInputChangeModelType} 
            validate={(name) => (name.trim() ? undefined : 'Model type is required')}
            submitted={submitted}/>
        </div>
        <h1 className={`tw-flex tw-text-md tw-text-gray-700 tw-px-6`}>
          Other notes?*
        </h1>
        <div className="tw-flex tw-w-full tw-pt-2">
          <TextInput 
            id="description" 
            label=""
            value={formData.description} 
            onChange={handleInputChangeDescription}
            submitted={submitted}
            />
        </div>
        </div>  
        
      </form>
  </div>         
    )
}

export default InstallationAsset