import React, { useState, useEffect } from 'react';
import { getDeviceTypes, getDevices, getDeviceDeployments, getRoom, getBuilding } from '../../../../api/api';
import { createDeviceDeployment, getAssets, updateOrganizationDevices} from '../../../../api/api-ts';
import { DeviceType, Device, DeviceDeployment, Asset, Room } from "../../../../types/dataTypes";
import { useLocation, useNavigate } from 'react-router-dom'; 
import Button from "../../../../components/Button";
import TextInput from '../../components/TextInput';
import CollapsibleSection from '../../components/CollapsibleSection';
import InstallationAsset from './Asset';
import SelectInput from '../../components/SelectInput';
import { TempState } from '../../../../types/componentTypes';
import GenericSelect from '../../../../components/GenericSelect';
import HintButton from '../../components/HintButton';

interface Props {
  formData: DeviceDeployment;
  setFormData: React.Dispatch<React.SetStateAction<DeviceDeployment>>;
  clearInput: boolean;
  submitted: boolean;
  deployedDeviceIds: string[];
}

const InstallationDevice: React.FC<Props> = ({ formData, setFormData, clearInput, submitted, deployedDeviceIds}) => {

  const location = useLocation(); 
  const queryParams = new URLSearchParams(location.search);

  let roomId = queryParams.get("roomId") || ''; 
  let buildingId = queryParams.get("buildingId") || '';

  const [deviceTypes, setDeviceTypes] = useState<DeviceType[]>([]);
  const [selectedDeviceTypeId, setSelectedDeviceTypeId] = useState<DeviceType | undefined>();
  const [selectedDeviceId, setSelectedDeviceId] = useState(''); 
  const [devices, setDevices] = useState<Device[]>([]);
  const [filteredDevices, setFilteredDevices] = useState<Device[]>([]);
  const [installDescription, setInstallDescription] = useState('');
  //const [deployedDeviceIds, setDeployedDeviceIds] = useState<string[]>([]);

  const [deviceSearch, setDeviceSearch] = useState(''); 
  const [showSuggestions, setShowSuggestions] = useState(false); 
  const [currentBuildingOrgId, setCurrentBuildingOrgId] = useState('');
  

  useEffect(() => {
    setSelectedDeviceTypeId(undefined);
    setSelectedDeviceId('');
    setDeviceSearch('');
    setInstallDescription('');
  }, [clearInput]);
  
  useEffect(() => {
    const fetchOrganization = async () => {
      try{
        let currentBuilding = await getBuilding(buildingId);
      setCurrentBuildingOrgId(currentBuilding.organization_id)
      }catch(error){
        console.error("Failed to fetch organization ID for current building: ", error)
      }
    }
    fetchOrganization();
  }, [roomId]);

  
  const fetchDevices = async (): Promise<Device[]> => {
    try {
      const allDevices = await getDevices();
      setDevices(allDevices);
      return allDevices; 
    } catch (error) {
      console.error('Failed to fetch devices:', error);
      return []; 
    }
};

  useEffect(() => {
    const fetchDeviceTypes = async () => {
      try {
        const types = await getDeviceTypes();
        setDeviceTypes(types);
      } catch (error) {
        console.error('Failed to fetch device types:', error);
      }
    };
    fetchDeviceTypes();
    fetchDevices();
  }, []);

  useEffect(() => {
    if (selectedDeviceTypeId) {
      const devicesOfSelectedType = devices.filter(device => device.type_id === selectedDeviceTypeId.id);
      const nonDeployedDevices = devicesOfSelectedType.filter(device => !deployedDeviceIds.includes(device.id));
      const searchFilteredDevices = deviceSearch.trim() ? nonDeployedDevices.filter
      (device => device.serial.toLowerCase().includes(deviceSearch.toLowerCase().trim())) : nonDeployedDevices;
      setFilteredDevices(searchFilteredDevices);
    } else {
      setFilteredDevices([]);
    }
  }, [selectedDeviceTypeId, devices, deployedDeviceIds, deviceSearch]);

  const handleDeviceSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDeviceSearch(e.target.value);
    setShowSuggestions(true);
  };

  const handleDeviceSelect = (deviceId: string) => {
    console.log(deviceId)
    setSelectedDeviceId(deviceId);
    setFormData({ ...formData, device_id: deviceId});
    const selectedDevice = filteredDevices.find(device => device.id === deviceId);
    if (selectedDevice) {
      setDeviceSearch(selectedDevice.serial);
    }
    setShowSuggestions(false);
  };

  const handleDeviceTypeChange = (type: DeviceType | undefined) => {
    if(type && type.id){
      setSelectedDeviceTypeId(type)
    } else {
      setSelectedDeviceTypeId(selectedDeviceTypeId)
    }
  };

  return (
    <div>
      <form  onSubmit={e => e.preventDefault()}>
        <div className=" tw-pb-2">
          <div className="tw-px-6 tw-pb-2">
            <h1 className="tw-items-center tw-flex tw-justify-center tw-text-17 tw-font-bold tw-px-6">Device
              <HintButton text="Example: A pump can belong to a ventilation system. Select the ventilation system in the list, or create a new by pushing the button."/>
            </h1>
          </div>
          <h1 className={`tw-items-center tw-flex tw-text-md tw-text-gray-700 tw-px-6`}>
            What type of device are you instaling?*
          </h1>
          <div className="tw-flex tw-w-full tw-px-2 tw-py-2">
              <div className="tw-w-full tw-flex tw-items-center tw-justify-center tw-align-middle">
                <GenericSelect
                  title=""
                  selected={selectedDeviceTypeId} // Match the selected asset by id
                  setSelected={handleDeviceTypeChange}
                  mapAvailableToSelectable={function(d: DeviceType) {
                    return { id: d.id, name: d.model ? `${d.brand} - ${d.model}` : 'Unknown Device'}
                  }}
                  available={deviceTypes}
                  border="yes" // Or adjust based on your styling requirements
                  className="tw-w-full tw-pr-2"
                  selectedName="Select Device type" // This is the name for the default selection
                  />
              </div>
          </div>
          <h1 className={`tw-items-center tw-flex tw-text-md tw-text-gray-700 tw-px-6 tw-pt-4`}>
            Search for serial number*
          </h1>
          <div className="tw-flex tw-w-full tw-pt-2">
            <TextInput 
              id="deviceSerial" 
              label=""
              value={deviceSearch} 
              onChange={handleDeviceSearchChange} 
              placeholder="Device serial"
              validate={(serial) => (serial.trim() ? undefined : 'Device serial is required')}
              submitted={submitted}/>
          </div>
        </div>

        <div className="tw-relative">
          {showSuggestions && deviceSearch && filteredDevices.length > 0 && (
            <div className="dropdown-wrapper tw-absolute tw-z-50">
              <ul className="tw-list-none tw-border tw-bg-white tw-border-gray-400 tw-rounded-lg tw-shadow-lg">
                {filteredDevices.map(device => (
                  <li className="tw-p-1 tw-rounded-lg tw-cursor-default" key={device.id} onClick={() => handleDeviceSelect(device.id)}>
                    {device.serial}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default InstallationDevice;
