import {sortTable} from "../../../../functions/helpers.js"
import {Asset, Device, DeviceDeployment, DeviceStatus, Organization, Room} from "../../../../types/dataTypes"
import {ConditionRow} from "../../../../types/componentTypes";
import TrafficLight from "../../../../components/TrafficLight";

type DeviceListProps = {
  room: Room
  cmRows: ConditionRow[]
  openQuickView: Function
  isQuickViewOpen: Function
  currentAsset: Asset | undefined
}

export default function AssetList({
                                    room,
                                    cmRows,
                                    openQuickView,
                                    isQuickViewOpen,
                                    currentAsset,
                                  }: DeviceListProps) {
  const dynamicPadding = "tw-px-2 md:tw-px-6"

  return (
    <div>
      <div className="">
        <table className="tw-w-full tw-text-left" id={room.id}>
          <thead className="tw-text-typography-muted tw-border-b tw-border-stroke-off-white tw-cursor-pointer">
          <tr>
            <th
              scope="col"
              className={`${dynamicPadding}  tw-py-3 tw-font-light hover:tw-underline ${isQuickViewOpen()
                ? "tw-hidden"
                : "tw-hidden md:tw-table-cell"
              }`}
              onClick={() => sortTable(0, room.id)}
            >
              Sensor Types
            </th>
            <th
              scope="col"
              className={`${dynamicPadding} tw-pl-6 tw-py-3 tw-font-light hover:tw-underline`}
              onClick={() => sortTable(1, room.id)}
            >
              Asset
            </th>
            <th
              scope="col"
              className={`${dynamicPadding}  tw-py-3 tw-font-light hover:tw-underline`}
              onClick={() => sortTable(3, room.id)}
            >
              Status
            </th>
            <th
              scope="col"
              className={`${dynamicPadding}  tw-py-3 tw-font-light tw-cursor-default ${isQuickViewOpen()
                ? "tw-hidden"
                : "tw-hidden md:tw-table-cell"
              }`}
            >
              Analytics
            </th>
          </tr>
          </thead>
          <tbody>
          {cmRows.map((cmr) => (
            <tr
              key={cmr.asset.id}
              className={`tw-bg-white hover:tw-bg-secondary-darker-off-white hover:tw-bg-opacity-30 tw-border-b tw-border-stroke-off-white last:tw-border-none tw-font-bold ${currentAsset === cmr.asset
                ? "tw-bg-secondary-darker-off-white tw-bg-opacity-30"
                : ""
              } ${isQuickViewOpen() ? "tw-cursor-pointer" : ""}`}
              onClick={() => {
                openQuickView(true, cmr.asset, cmr.devices[0], cmr.devices[0].deviceStatus!
                )
              }}>
              <td
                className={`${dynamicPadding}  tw-py-3 ${isQuickViewOpen()
                  ? "tw-hidden"
                  : "tw-hidden md:tw-table-cell"
                }`}
              >
                <div className="tw-flex tw-flex-nowrap tw-shrink-0 tw-h-full">
                  {/* TODO: If adding more vibration devices the check below needs
                  to account for that */}
                  {cmr.devices.map(dev => (
                    <img
                      key={"deviceTypeImg-"+dev.id}
                      src={
                        dev.type?.model === "RuuviTag"
                          ? "icon-device.svg"
                          : "icon-sound.svg"
                      }
                      alt="device type icon"
                      className="tw-w-[20px] tw-mb-0.5"
                    />))}
                </div>
              </td>
              <td className={`${dynamicPadding} tw-pl-6 tw-py-3 tw-break-all lg:tw-break-words`}>
                {cmr.asset.nice_name ?? cmr.asset.name}
              </td>
              <td className={`${dynamicPadding}  tw-py-3`}>
                <TrafficLight status={cmr.status}/>
              </td>
              <td
                className={`${dynamicPadding}  tw-py-3 tw-pl-11 tw-cursor-pointer ${isQuickViewOpen()
                  ? "tw-hidden"
                  : "tw-hidden md:tw-table-cell"
                }`}
                onClick={() => {} /*Do nothing here, whole row has onClick, no need to double*/}
              >
                <img src="icon-arrow.svg" alt="arrow icon"></img>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
