import {Alarm, Asset, Building, Device, DeviceDeployment, Organization, Room} from "../../types/dataTypes";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router";
import {TempState} from "../../types/componentTypes";
import Titlebar from "../../components/Titlebar";
import Header from "../../components/Header";
import Search from "../../components/Search";
import OrganizationSelect from "../../components/OrganizationSelect";
import {availableOrganizations} from "../../functions/organizationHelpers";
import GenericSelect from "../../components/GenericSelect";
import DropdownWrapper from "../../components/DropdownWrapper";
import {getAlarms} from "../../api/api";
import {AlarmList} from "./Components/AlarmList";
import Button from "../../components/Button";

export type AlarmRow = {
  alarm: Alarm
  device: Device | undefined
  deployment: DeviceDeployment | undefined
  building: Building | undefined
  room: Room | undefined
}

export const AlarmOverview = ({state}: { state: TempState }) => {

  const navigation = useNavigate()
  const location = useLocation()

  const availableOrgs = availableOrganizations(state.organizations, state.buildings)

  const [alarms, setAlarms] = useState<Alarm[]>()
  const [alarmRows, setAlarmRows] = useState<AlarmRow[]>([])
  const [selectedOrg, setSelectedOrg] = useState<Organization>()
  const [selectedBuilding, setSelectedBuilding] = useState<Building>()
  const [selectedRoom, setSelectedRoom] = useState<Room>()
  const [selectedAsset, setSelectedAsset] = useState<Asset>()
  const [search, setSearch] = useState("")

  const queryParams = new URLSearchParams(location.search)

  useEffect(() => {
    let orgId = queryParams.get("orgId")
    if (orgId === null && selectedOrg === undefined && availableOrgs.length > 0) {
      let org = availableOrgs[0]
      navigation({
        search: "orgId=" + org.id
      })
    }
  }, [availableOrgs])

  useEffect(() => {
    const loadAlarms = async () => {
      let alarms = await getAlarms([], [selectedOrg?.id], null, null, 1)
      setAlarms(alarms)
    }
    loadAlarms()
  }, [selectedOrg])

  useEffect(() => {
    let rows: AlarmRow[] = []
    alarms?.forEach(alarm => {
      let device = state.devices?.find(d => d.id === alarm.device_id)
      let deviceDeployment: DeviceDeployment | undefined = state.deviceDeployments?.find(dd => dd.device_id === device?.id && dd.start_time <= alarm.timestamp
        && ((dd.end_time === null || dd.end_time === undefined) || (alarm.timestamp < dd.end_time)))
      let room = state.rooms?.find(r => r.id === deviceDeployment?.room_id)
      let building = state.buildings?.find(b => b.id === room?.building_id)
      rows.push({
        alarm: alarm, device: device, deployment: deviceDeployment,
        room: room, building: building
      })
    })
    setAlarmRows(rows)
  }, [alarms, state])

  function parseSearch(search: string) {
    search = search.replace(" ", "%20")
    return search
  }
  function unparseSearch(search: string){
    search = search.replace("%20", " ")
    return search
  }

  useEffect(() => {
      // Set state from params
      let orgId = queryParams.get("orgId")
      let buildingId = queryParams.get("buildingId")
      let roomId = queryParams.get("roomId")
      let assetId = queryParams.get("assetId")
      let search = queryParams.get("search")

      let org: Organization | undefined = undefined
      let building: Building | undefined = undefined
      let room: Room | undefined = undefined
      let asset: Asset | undefined = undefined

      if (orgId !== null && state.organizations.map(o => o.id).includes(orgId)) {
        org = state.organizations.find(o => o.id === orgId)
      }
      if (org !== undefined && buildingId !== null && state.buildings && state.buildings.map(b => b.id).includes(buildingId)) {
        building = state.buildings.find(b => b.id === buildingId)
      }
      if (building !== undefined && roomId !== null && state.rooms && state.rooms.map(r => r.id).includes(roomId)) {
        room = state.rooms.find(r => r.id === roomId)
      }
      if (room !== undefined && assetId !== null && state.assets && state.assets.map(a => a.id).includes(assetId)) {
        asset = state.assets.find(a => a.id === assetId)
      }

      if (search !== null){
          setSearch(search);
          (document.getElementById("search-input") as HTMLInputElement).value = search
      }

      setSelectedOrg(org)
      setSelectedBuilding(building)
      setSelectedRoom(room)
      setSelectedAsset(asset)

    }
    ,
    [location, state]
  )

  function filterAlarms(alarmRows: AlarmRow[]) {
    if (selectedBuilding !== undefined) {
      alarmRows = alarmRows.filter(row => row.building?.id === selectedBuilding.id)
    }
    if (selectedBuilding !== undefined && selectedRoom !== undefined) {
      alarmRows = alarmRows.filter(row => row.room?.id === selectedRoom.id)
    }
    if (selectedRoom !== undefined && selectedAsset !== undefined) {
      alarmRows = alarmRows.filter(row => row.deployment?.assets.map(a => a.id).includes(selectedAsset.id))
    }
    const searchRegex = new RegExp(search, "i") // 'i' means ignore case
    alarmRows = alarmRows.filter(row => {
        return (
          searchRegex.test(row.room?.name ?? "THIS SHOULD NEVER BE CONTAINED IN THIS SEARCH") ||
          searchRegex.test(row.room?.nice_name ?? "THIS SHOULD NEVER BE CONTAINED IN THIS SEARCH") ||
          searchRegex.test(row.building?.address ?? "THIS SHOULD NEVER BE CONTAINED IN THIS SEARCH") ||
          searchRegex.test(row.building?.name ?? "THIS SHOULD NEVER BE CONTAINED IN THIS SEARCH") ||
          row.deployment?.assets.some(
            asset => { return (
              searchRegex.test(asset.name) ||
              searchRegex.test(asset.nice_name ?? "THIS SHOULD NEVER BE CONTAINED IN THIS SEARCH") ||
              searchRegex.test(asset.model) ||
              searchRegex.test(asset.id))
            }) ||
          searchRegex.test(row.device?.id ?? "THIS SHOULD NEVER BE CONTAINED IN THIS SEARCH") ||
          searchRegex.test(row.device?.serial ?? "THIS SHOULD NEVER BE CONTAINED IN THIS SEARCH")
        )
      }
    )
    return alarmRows
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      queryParams.set("search", search)
      navigation({search: queryParams.toString()})
    }, 3000)
      return () => clearTimeout(delayDebounceFn)
  }, [search])


  function setOrgWithNavigate(org: Organization) {
    navigation({search: "orgId=" + org.id})
  }

  function setFilterWithNavigate(param: string, value: string | undefined) {
    const queryParams = new URLSearchParams(location.search)
    if (value !== undefined) {
      queryParams.set(param, value)
    } else {
      queryParams.delete(param)
    }
    navigation({search: queryParams.toString()})
  }

  const handleSearch = (event: any) => {
    if (event.target.value.length > 0) {
      document.getElementById("search-icon")!.style.visibility = "hidden"
    } else {
      document.getElementById("search-icon")!.style.visibility = "visible"
    }
    setSearch(event.target.value)
  }

  function resetFilter(){
    setSearch("");
    (document.getElementById("search-input")! as HTMLInputElement).value = ""
    setSelectedBuilding(undefined)
    setSelectedRoom(undefined)
    setSelectedAsset(undefined)
    navigation({search: "orgId="+ selectedOrg?.id} )
  }

  return (
    <div className="tw-h-full">
      <div>
        <Titlebar headline="Alarm Overview"/>
        <span className={`tw-pt-1 tw-flex-wrap`}>
          <Header headline="All Alarms" headlineStyles="tw-pt-2">
            <Search handleSearch={handleSearch}></Search>
            <OrganizationSelect
              availableOrganizations={availableOrgs}
              selected={selectedOrg}
              setSelected={setOrgWithNavigate}
              className="tw-flex-wrap tw-mt-2 lg:tw-absolute lg:tw-right-10"/>
          </Header>
        </span>
      </div>
      <div className={"tw-flex tw-items-center tw-ml-10 tw-text-lg tw-mb-5"}>Filter</div>
      <div className={"tw-flex tw-flex-wrap tw-gap-5 tw-ml-10 tw-mb-5"}>
        <GenericSelect title={"Building"}
                       setSelected={(building: Building | undefined) => setFilterWithNavigate("buildingId", building?.id)}
                       mapAvailableToSelectable={function (b: Building) {
                         return {id: b.id, name: b.name ?? b.address}
                       }}
                       selected={selectedBuilding}
                       available={state.buildings?.filter(b => b.organization_id === selectedOrg?.id)}/>
        <GenericSelect title={"Room"}
                       setSelected={(room: Room | undefined) => setFilterWithNavigate("roomId", room?.id)}
                       mapAvailableToSelectable={function (r: Room) {
                         return {id: r.id, name: r.nice_name ?? r.name}
                       }}
                       selected={selectedRoom}
                       available={state.rooms?.filter(r => r.building_id === selectedBuilding?.id)}
                       disabled={selectedBuilding === undefined}
        />
        <GenericSelect title={"Asset"}
                       setSelected={(asset: Asset | undefined) => setFilterWithNavigate("assetId", asset?.id)}
                       mapAvailableToSelectable={function (a: Asset) {
                         return {id: a.id, name: a.nice_name ?? a.name}
                       }}
                       selected={selectedAsset}
                       available={state.assets?.filter(a => a.room_id === selectedRoom?.id)}
                       disabled={selectedRoom === undefined}
        /> {/*TODO: Fix select methods to clear selected if parent is unselected*/}
        <Button styles={"tw-max-h-[40px] tw-py-0"} type={"reset"} size={"medium"} variant={"secondary"} onClick={resetFilter}>Clear</Button>

      </div>
      <div>
        <DropdownWrapper
          key={"dropdown-alarms"}
          title={"Alarms"}
          titleIcon={<img src={"alarm-bell-svgrepo-com.svg"}
                          className={"tw-inline-flex tw-w-6 tw-h-6"}/>}
          defaultOpen={true}
        >
          <AlarmList alarmRows={filterAlarms(alarmRows).sort( (a,b) => b.alarm.timestamp - a.alarm.timestamp)}/>
        </DropdownWrapper>
      </div>
    </div>
  )
}