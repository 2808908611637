import React, { useEffect, useRef, useState } from "react"
import SectionWrapper from "../../components/SectionWrapper"
import Header from "../../components/Header"
import DeviceQuickView from "./components/DeviceQuickView"
import DropdownWrapper from "../../components/DropdownWrapper"
import Titlebar from "../../components/Titlebar"
import {
  Alarm,
  Asset,
  Building,
  Device, DeviceDeployment,
  DeviceStatus,
  ModelTemplate,
  Organization, Room,
} from "../../types/dataTypes"
import Search from "../../components/Search"
import { getActiveModelTemplates, getAlarm, getAlarms, getDeviceCondition } from "../../api/api"
import { ConditionRow, TempState } from "../../types/componentTypes"
import { debounce } from "../../functions/general"
import OrganizationSelect from "../../components/OrganizationSelect"
import AssetList from "./components/AssetList"
import IconStatusPill from "../../components/IconStatusPill"
import { BuildingOffice2Icon, CubeIcon } from "@heroicons/react/20/solid"
import { createConditionRowsForOrg } from "./ConditionOrganizationDataUtil"
import TrafficLight from "../../components/TrafficLight"
import { availableOrganizations } from "../../functions/organizationHelpers"
import { useLocation, useNavigate } from "react-router"

type ConditionMonitoringProps = {
  state: TempState
}

export default function ConditionMonitoring({
                                              state,
                                            }: ConditionMonitoringProps) {
  const [tmpSearch, setTmpSearch] = useState("")
  const [search, setSearch] = useState("")
  const [devices, setDevices] = useState<Device[]>([])
  const [deviceDataLoading, setDeviceDataLoading] = useState(true)
  const [showQuickView, setShowQuickView] = useState(false)
  const [windowHeight, setWindowHeight] = useState(0)
  const [bannerHeight, setBannerHeight] = useState(0)
  const [quickViewHeight, setQuickViewHeight] = useState(0)
  const [selectedOrg, setSelectedOrg] = useState<Organization>()
  const [cmRows, setCmRows] = useState<ConditionRow[]>([])
  const [selectedBuilding, setSelectedBuilding] = useState<Building>()
  const [selectedRoom, setSelectedRoom] = useState<Room>()
  const [currentAsset, setCurrentAsset] = useState<Asset>()
  const [alarms, setAlarms] = useState<Alarm[]>([])
  const [filteredCmRows, setFilteredCmRows] = useState<ConditionRow[]>([])
  const pageContainerRef = useRef<any>(null)
  const bannerContainerRef = useRef<any>(null)
  const quickViewContainerRef = useRef<any>(null)

  const availableOrgs = availableOrganizations(state.organizations, state.buildings)

  /** Navigation **/
  const location = useLocation()
  const navigation = useNavigate()
  const queryParams = new URLSearchParams(location.search)

  useEffect(() => {
      /** Set state from params, never set the location states outside of this useEffect,
       * let the search params handle navigation, and use navigate instead **/
      let orgId = queryParams.get("orgId")
      let buildingId = queryParams.get("buildingId")
      let roomId = queryParams.get("roomId")
      let assetId = queryParams.get("assetId")
      let searchParam = queryParams.get("search")

      let org: Organization | undefined = undefined
      let building: Building | undefined = undefined
      let room: Room | undefined = undefined
      let asset: Asset | undefined = undefined

      if (orgId !== null && state.organizations.map(o => o.id).includes(orgId)) {
        org = state.organizations.find(o => o.id === orgId)
      }
      if (org !== undefined) {
        if (buildingId !== null && state.buildings && state.buildings.map(b => b.id).includes(buildingId)) {
          building = state.buildings.find(b => b.id === buildingId)
        }
      }
      if (building !== undefined && roomId !== null && state.rooms && state.rooms.map(r => r.id).includes(roomId)) {
        room = state.rooms.find(r => r.id === roomId)
      }
      if (room !== undefined && assetId !== null && state.assets && state.assets.map(a => a.id).includes(assetId)) {
        asset = state.assets.find(a => a.id === assetId)
      }

      if (searchParam !== null) {
        setTmpSearch(searchParam)
        setSearch(searchParam);
        (document.getElementById("search-input") as HTMLInputElement).value = searchParam
      } else {
        (document.getElementById("search-input") as HTMLInputElement).value = ""
        setSearch("")
      }

      setSelectedOrg(org)
      setSelectedBuilding(building)
      setSelectedRoom(room)
      setCurrentAsset(asset)
      if (asset !== undefined) {
        setShowQuickView(true)
      }

    }
    ,
    [location, state],
  )

  useEffect(() => {
    /** Close quickview if assetId not in search params**/
    if (!location.search.includes("assetId")) {
      closeQuickView()
    }
  }, [location])

  /** Handle scaling of windows **/

  const updateContainerHeights = () => {
    setWindowHeight(pageContainerRef.current.clientHeight)
    setBannerHeight(bannerContainerRef.current.clientHeight)
    setQuickViewHeight(quickViewContainerRef.current.clientHeight)
  }

  useEffect(() => {
    updateContainerHeights()
  })

  useEffect(() => {
    const debouncedHandleResize = debounce(() => updateContainerHeights(), 1000)

    window.addEventListener("resize", debouncedHandleResize)

    return () => {
      window.removeEventListener("resize", debouncedHandleResize)
    }
  }, [])

  /** Handle quickview **/

  const handleShowQuickView = (isOpen: boolean, asset: Asset, device: Device, deviceStatus: DeviceStatus) => {
    if (isOpen) {
      if (currentAsset != undefined) {
        currentAsset.isOpen = false
      }
      setShowQuickView(true)
      queryParams.set("assetId", asset.id)
      asset.isOpen = true
      navigation({ search: queryParams.toString() })
    } else {
      setShowQuickView(false)
      asset.isOpen = false
    }
  }

  const closeQuickView = () => {
    setShowQuickView(false)
    if (currentAsset != undefined) {
      currentAsset!.isOpen = false
    }
    queryParams.delete("assetId")
  }

  const isQuickViewOpen = () => {
    return showQuickView
  }

  /** Handle search
   *
   * Search variables split into two, search and tmpSearch, to facillitate delay between writing and searching,
   * to avoid a new load for each letter written.
   * **/

  const handleSearch = (event: any) => {
    if (event.target.value.length > 0) {
      document.getElementById("search-icon")!.style.visibility = "hidden"
    } else {
      document.getElementById("search-icon")!.style.visibility = "visible"
    }
    setTmpSearch(event.target.value)
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (tmpSearch.length > 0) {
        queryParams.set("search", tmpSearch)
      } else {
        queryParams.delete("search")
      }
      navigation({ search: queryParams.toString() })
    }, 1500)
    return () => clearTimeout(delayDebounceFn)
  }, [tmpSearch])


  /** Handle data flow **/

  useEffect(() => {
    /* Set initial organization if not selected manually */
    let orgId = queryParams.get("orgId")
    if (orgId === null && selectedOrg === undefined && availableOrgs.length > 0) {
      let org = availableOrgs[0]
      navigation({
        search: "orgId=" + org.id,
      }, { "replace": true })
    }
  }, [availableOrgs])

  useEffect(() => {
    /** Update devices from state to include only devices that are currently active in a model template **/
    const fetchDevices = async () => {
      setDeviceDataLoading(true)
      if ((state.deviceDeployments?.length ?? 0) > 0 && state.deviceDeployments && selectedOrg !== undefined) {
        try {
          setDeviceDataLoading(true)
          // Remove devices that don't have an organization
          let activeDeviceDeployments = state.deviceDeployments?.filter((dd: DeviceDeployment) => dd.end_time === null)
          let activeDevices = state.devices.filter(device => activeDeviceDeployments.map(dd => dd.device_id).includes(device.id))
          let data = activeDevices.filter((device: Device) => device.organization?.id === selectedOrg?.id)
          setDevices(data)
        } catch (err) {
          console.log(err)
        }
        setDeviceDataLoading(false)
      }
    }
    fetchDevices()
  }, [state, selectedOrg])

  useEffect(() => {
    /* Fetch alarms */
    const fetchDeviceAlarms = async () => {
      const now = new Date()
      if (selectedOrg !== undefined && devices.length > 0) {
        let alarmPromise = getAlarms(
          [],
          [selectedOrg.id], new Date(now.getTime() - 90 * 24 * 60 * 60 * 1000), now, 1) // Only get for last 90 days.
        alarmPromise.then(alarms =>
          setAlarms(alarms),
        )
      }

    }
    fetchDeviceAlarms()
  }, [devices, selectedOrg])

  useEffect(() => {
    /* Create Condition Monitoring Rows (cmRows) after alarms are loaded */
    let orgbuilds = state.buildings?.filter(b => b.organization_id === selectedOrg?.id) ?? []
    let cmRows = createConditionRowsForOrg(orgbuilds, state.rooms, state.assets, state.deviceDeployments,
      devices, alarms) //Use updated devices here, to ensure only cm activated devices are in rows.
    setCmRows(cmRows)
  }, [alarms])

  useEffect(() => {
    /* Filter created rows by search*/

    const filterDataBySearch = () => {
      const searchRegex = new RegExp(search, "i") // 'i' means ignore case
      let filteredCmRows = cmRows.filter(row => {
        return (
          searchRegex.test(row.room.name) ||
          searchRegex.test(row.room.nice_name ?? "THIS SHOULD NEVER BE CONTAINED IN THIS SEARCH") ||
          searchRegex.test(row.building.address) ||
          searchRegex.test(row.building.name ?? "THIS SHOULD NEVER BE CONTAINED IN THIS SEARCH") ||
          searchRegex.test(row.asset.name) ||
          searchRegex.test(row.asset.model) ||
          searchRegex.test(row.asset.id) ||
          row.devices.some(d => {
              return (
                searchRegex.test(d.id) ||
                searchRegex.test(d.serial)
              )
            },
          )
        )
      })
      return filteredCmRows
    }

    setFilteredCmRows(filterDataBySearch())
  }, [cmRows, selectedBuilding, selectedRoom, search])

  useEffect(() => {
    /* Change to correct building and room after cm-rows are filtered*/
    if (filteredCmRows !== undefined && filteredCmRows.length > 0
      && filteredCmRows[0].building.organization_id === selectedOrg?.id) {
      let buildings = filteredCmRows.map(r => r.building).filter((building, i, arr) => {
        return arr.indexOf(arr.find(b => b.id === building.id)!) === i
      })
      if (selectedBuilding && (buildings.length > 0) && !buildings.map(b => b.id).includes(selectedBuilding?.id)) {
        selectBuilding(buildings[0])
      } else if (selectedBuilding === undefined && buildings.length > 0) {
        selectBuilding(buildings[0])
      } else if (selectedOrg && selectedBuilding && selectedBuilding.organization_id !== selectedOrg.id) {
        selectBuilding(buildings[0])
      }
      let rooms = filteredCmRows.map(row => row.room).filter((room, i, arr) => {
        return arr.indexOf(arr.find(r => r.id === room.id)!) === i
      })
      if (selectedRoom && !rooms.includes(selectedRoom)) {
        selectRoom(rooms[0])
      }
    }
  }, [filteredCmRows])

  function selectOrg(org: Organization) {
    queryParams.set("orgId", org.id)
    queryParams.delete("buildingId")
    queryParams.delete("roomId")
    queryParams.delete("assetId")
    queryParams.delete("search")
    navigation({ search: queryParams.toString() })
    closeQuickView()
    // TODO: As navigation is figured out after org is changed, we add three extra steps to history. Way around that to make backbutton usable?
  }

  function selectBuilding(pressedBuilding: Building) {
    let room = state?.rooms?.filter(r => filteredCmRows.map(row => row.room.id).includes(r.id) && r.building_id === pressedBuilding.id)[0]
    queryParams.set("roomId", room?.id ?? "")
    queryParams.set("buildingId", pressedBuilding.id)
    closeQuickView()
    let options = {}
    if (!location.search.includes("buildingId") || location.search.includes(queryParams.toString())) { //Replace in history if only org is set, or adding duplicate
      options = { "replace": true }
    }
    navigation({ search: queryParams.toString() }, options)

  }

  function selectRoom(pressedRoom: Room) {
    queryParams.set("roomId", pressedRoom?.id ?? "")
    closeQuickView()

    navigation({ search: queryParams.toString() })

  }

  return (
    <div className="tw-h-full" ref={pageContainerRef}>
      <div ref={bannerContainerRef}>
        <Titlebar headline="Condition Monitoring" />
        <span className={`tw-pt-1 tw-flex-wrap ${showQuickView ? "tw-hidden lg:tw-block" : ""}`}>
          <Header headline="Buildings overview" headlineStyles="tw-pt-2">
            <Search handleSearch={handleSearch}></Search>
            <OrganizationSelect
              availableOrganizations={availableOrgs}
              selected={selectedOrg}
              setSelected={selectOrg}
              className="tw-flex-wrap tw-mt-2 lg:tw-absolute lg:tw-right-10" />
          </Header>
        </span>
      </div>
      <div className="tw-flex tw-flex-wrap tw-flex-row tw-px-10 tw-pb-10 tw-gap-4">
        <div className="tw-flex tw-items-center tw-justify-center tw-text-md">Building</div>
        {(state.buildings?.length ?? 0 > 0) && selectedOrg && ( //TODO: Need to use cmrow buildings here?
          state.buildings!
            .filter(b => filteredCmRows.map(r => r.building.id).includes(b.id))
            .sort((a: Building, b: Building) => {
              return (a.name ?? a.address).localeCompare((b.name ?? b.address))
            })
            .map((building) => {

              return (
                <IconStatusPill key={building.id} className={""} selected={selectedBuilding?.id === building.id}
                                setSelected={(id: String) => {
                                  selectBuilding(building)
                                  closeQuickView()
                                }}

                                name={building?.name ?? building.address}
                                id={building.id} status={building.status ?? "UNKNOWN"}
                                icon={<div className={"tw-flex"}><BuildingOffice2Icon
                                  className="tw-h-5 tw-w-5 tw-text-gray-400"
                                  aria-hidden="true" />
                                  {(building.meta && Object.keys(building.meta).includes("muted_to")
                                      && new Date(building.meta["muted_to"] * 1000) > new Date()) &&
                                    <img alt={"muted-building"} src={"muted-alarm-bell.svg"}
                                         className={"tw-h-5 tw-w-5"} />}
                                </div>} />
              )
            }))
        }
      </div>
      {selectedBuilding &&
        <div className="tw-flex tw-flex-wrap tw-flex-row tw-px-10 tw-pb-10 tw-gap-4">
          <div className="tw-flex tw-items-center tw-justify-center tw-text-md">Room</div>
          {(state.rooms?.length ?? 0 > 0) && selectedOrg && ( //TODO: Need to use cmrow buildings here?
            state.rooms!.filter(room => filteredCmRows.map(row => row.room.id).includes(room.id) && room.building_id === selectedBuilding.id)
              .sort((a: Room, b: Room) => {
                return ((a.nice_name !== undefined && a.nice_name !== null) ? a.nice_name : a.name)
                  .localeCompare((b.nice_name !== undefined && b.nice_name !== null) ? b.nice_name : b.name)
              })
              .map((room) => {

                return (
                  <IconStatusPill key={room.id} className={"tw-text-sm"} selected={selectedRoom?.id === room.id}
                                  setSelected={(id: String) => {
                                    selectRoom(room)
                                  }}

                                  name={room?.nice_name ?? room.name}
                                  id={room.id} status={room.status ?? "UNKNOWN"}
                                  icon={<CubeIcon className="tw-h-5 tw-w-5 tw-text-gray-400"
                                                  aria-hidden="true" />} />
                )
              }))
          }
        </div>
      }
      <div className="tw-flex tw-flex-row tw-flex-nowrap">
        <div className={`tw-flex-auto ${showQuickView ? `tw-hidden lg:tw-block lg:tw-overflow-y-scroll` : ""}`}
             style={{
               height: showQuickView ?
                 (quickViewHeight > (windowHeight - bannerHeight) ?
                   `${quickViewHeight}px`
                   :
                   `calc(${windowHeight - bannerHeight}px - 1rem)`)
                 :
                 "calc(100% - 1rem)",
             }}>
          {selectedBuilding && selectedRoom && selectedRoom.building_id == selectedBuilding!.id && filteredCmRows.length > 0 &&
            <div key={selectedRoom.id} className={""}>
              <DropdownWrapper
                key={"dropdown-" + selectedRoom.id}
                title={selectedRoom.nice_name ?? selectedRoom.name}
                titleIcon={<div className={"tw-flex"}><TrafficLight
                  status={selectedRoom.status ?? "UNKNOWN"} />{(selectedBuilding.meta && Object.keys(selectedBuilding.meta).includes("muted_to")
                    && new Date(selectedBuilding.meta["muted_to"] * 1000) > new Date()) &&
                  <img title={"Building muted. Click to go to settings. "} alt={"muted-building"} src={"muted-alarm-bell.svg"} className={"tw-h-7 tw-ml-4"}
                    onClick={() => navigation(`buildings/${selectedBuilding?.id}/settings`)}/>
                }</div>}
                defaultOpen={true}
              >
                {((state.assets?.length ?? 0 > 0) && (state.deviceDeployments?.length ?? 0 > 0)) &&
                  (<AssetList
                    key={"assetlist-" + selectedRoom.id}
                    room={selectedRoom}
                    cmRows={filteredCmRows.filter(cmr => cmr.room.id === selectedRoom.id)}
                    currentAsset={currentAsset}
                    openQuickView={handleShowQuickView}
                    isQuickViewOpen={isQuickViewOpen}
                  />)}
              </DropdownWrapper>
            </div>
          }
          {devices.length === 0 && (
            <SectionWrapper styles="tw-border tw-ml-10 tw-p-5 tw-my-5 tw-mr-10">
              {deviceDataLoading ?
                <p className="tw-italic">
                  Loading devices...
                </p>
                :
                <p className="tw-italic">
                  Could not retrieve any devices that run Condition Monitoring. Please contact us for help.
                </p>}
            </SectionWrapper>
          )}
        </div>
        <div
          className={`tw-flex-[2_1_50%] tw-min-w-min ${showQuickView ? "tw-block" : "tw-hidden"
          }`}
        >
          <div ref={quickViewContainerRef}>
            <SectionWrapper
              styles="tw-border tw-sticky tw-top-[2.5rem] tw-self-start tw-p-5 tw-my-5 tw-mx-5 lg:tw-mt-0 lg:tw-m-10 ">
              {showQuickView && cmRows !== undefined && cmRows.find(cm => cm.asset == currentAsset) !== undefined && (
                <DeviceQuickView
                  key={currentAsset != undefined ? currentAsset.id : ""}
                  cmRow={cmRows.find(cm => cm.asset == currentAsset)!}
                  closeDeviceQuickView={closeQuickView}
                  onUpdate={updateContainerHeights}
                />
              )}
            </SectionWrapper>
          </div>

        </div>
      </div>
    </div>
  )
}
