// TODO: use OpenAPI definitions
// TODO: Split file into several files
import {base, options} from "./api-ts"

export const getDevices = async () => {
    const url = `${base}/v1/devices`
    let response = await fetch(url, {...options, method: "GET"})
    let data = await response.json()

    let devices = data.data

    for (const d of devices) {
        d.organization = data.organizations.find(o =>
            o ? o.id === d.organization_id : false
        )
    }

    return devices
}

export const getDevice = async deviceId => {
    const url = `${base}/v1/devices/${deviceId}`
    let response = await fetch(url, options)
    let data = await response.json()
    return data.data
}

export const getSoundlevels = async (
    deviceIds,
    organizationIds,
    start,
    end
) => {
    let url = `${base}/v1/soundlevels`
    let params = new URLSearchParams({
        start: Math.floor(start.getTime() / 1000),
        end: Math.floor(end.getTime() / 1000),
    })
    deviceIds.map(id => params.append("device", id))
    organizationIds.map(id => params.append("organization", id))
    url = url + "?" + params

    let response = await fetch(url, options)
    let data = await response.json()

    const soundLevels = data.data
    return soundLevels
}

export const getDeviceStatus = async (
    deviceIds,
    organizationIds,
    status_time
) => {
    let url = `${base}/v1/device_status`
    if (status_time == null) {
        status_time = new Date()
    }
    let params = new URLSearchParams({
        status_time: Math.floor(status_time.getTime() / 1000),
    })
    deviceIds.map(id => params.append("device", id))
    organizationIds.map(id => params.append("organization", id))
    url = url + "?" + params

    let response = await fetch(url, options)
    let data = await response.json()
    // only soundlevels returned for now, more status at a later point
    const soundLevels = data.data
    return soundLevels
}

export const getVibrations = async (deviceIds, organizationIds, start, end) => {
    let url = `${base}/v1/vibration`
    let params = new URLSearchParams({
        start: Math.floor(start.getTime() / 1000),
        end: Math.floor(end.getTime() / 1000),
    })
    deviceIds.map(id => params.append("device", id))
    organizationIds.map(id => params.append("organization", id))
    url = url + "?" + params

    let response = await fetch(url, options)
    let data = await response.json()

    const vibrations = data.data
    return vibrations
}

// Untested async variant of getAccount
export const getAccount = async () => {
    const url = `${base}/v1/account`

    let response = await fetch(url, options)
    let data = await response.json()
    const account = data.data

    return account
}

//TODO: Add signal argument
export const getAccountWithAbort = (signal) => {
    return new Promise((resolve, reject) => {
        const url = `${base}/v1/account`

        fetch(url, options, signal)
            .then((res) => {
                if (!res.ok) {
                    throw new Error("Request unsuccessful.")
                }
                return res.json()
            })
            .then((data) => {
                const account = data.data;
                resolve(account);
            })
            .catch((err) => {
                reject(err);
            })
    })
}


//Post Audioclip to database, returns upload-url
export const postAudioclip = async (
    filename,
    bucket = undefined,
    start_time = undefined,
    device_id = undefined,
    length = undefined
) => {
    const url = `${base}/v1/audioclip`

    let formData = {
        filename: filename,
    }

    if (bucket) formData["bucket"] = bucket
    if (start_time) formData["start_time"] = start_time
    if (device_id) formData["device_id"] = device_id
    if (length) formData["length"] = length

    let response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
    })

    return response
}

// User login
export const postUser = async (email, password, remember) => {
    const url = `${base}/login`

    var userData = new FormData()
    userData.append("email", email)
    userData.append("password", password)
    userData.append("remember", remember)

    let response = await fetch(url, {
        method: "POST",
        body: userData,
    })

    return response
}

// POST file to presigned url
export const uploadAudioClip = async (file, url) => {
    var formData = new FormData()
    formData.append("file", file)

    let response = await fetch(url, {
        method: "POST",
        body: formData,
    })

    return response
}

//Get files uploaded by current user
export const getSoundFiles = async (limit = 0, offset = 0) => {
    let url = `${base}/v1/audioclip`
    const user = await getAccount()

    let params = new URLSearchParams({
        user: [user.id],
        offset: offset,
        limit: limit,
    })
    url = url + "?" + params

    let response = await fetch(url, options)
    let data = await response.json()

    return data
}

export const getSoundFile = async soundFileId => {
    let url = `${base}/v1/audioclip/${soundFileId}`

    let response = await fetch(url, options)
    let data = await response.json()

    return data.data
}

export const getClassificationSummaryForSoundfile = async (
    soundFileId,
    maxClasses,
    timeResolution,
    classDetailLevel
) => {
    let url = `${base}/v1/classificationsummaries`
    let params = new URLSearchParams({
        audioclip: soundFileId,
        max_classes: maxClasses,
        time_resolution: timeResolution,
        class_detail_level: classDetailLevel,
    })
    url = url + "?" + params
    let response = await fetch(url, options)
    let data = await response.json()
    return data
}

export const checkClassificationsForSoundfiles = async soundfiles => {
    // Returns 1 classification(if there are any) for each given soundfile.
    // Only used to check whether any data exists for each file without fetching everything.
    let url = `${base}/v1/classifications`

    let soundfileIds = soundfiles.map(soundfile => soundfile.id)
    let devices = soundfiles.map(soundfile => soundfile.device_id)
    let uniqueDevices = [...new Set(devices)]
    let params = new URLSearchParams({
        distinct: 1,
    })
    //filter out null devices
    uniqueDevices = uniqueDevices.filter(d => d !== null)
    soundfileIds.map(id => params.append("audioclip", id))
    uniqueDevices.map(id => params.append("device", id))
    url = url + "?" + params

    let response = await fetch(url, {
        method: "GET",
        credentials: "include",
    })
    let data = await response.json()

    return data.data
}

export const editDevice = async (deviceId, deviceAttributes) => {
    let url = `${base}/v1/devices/${deviceId}`

    let formData = deviceAttributes

    let response = await fetch(url, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
    })

    return response
}

//TODO: Any validation with error reporting here?
export const registerDevice = async deviceAttributes => {
    let url = `${base}/v1/devices`

    let response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(deviceAttributes),
    })

    return response
}

export const getDeviceTypes = async () => {
    let url = `${base}/v1/devicetypes`
    let response = await fetch(url, options)
    let data = await response.json()

    let deviceTypes = data.data

    return deviceTypes
}

export const getDeviceMetrics = async (deviceIds, startTime, endTime) => {
    let url = `${base}/v1/devices/metrics`

    let params = new URLSearchParams({
        start: Math.floor(startTime.getTime() / 1000),
        end: Math.floor(endTime.getTime() / 1000),
    })
    deviceIds.map(id => params.append("device", id))

    url = url + "?" + params

    let response = await fetch(url, {
        method: "GET",
        ...options,
    })
    let data = await response.json()
    return data.data
}

//Generates content from different types of reports.
export const getReport = async (pdfStyleSelected, contentDict) => {
    contentDict["template-id"] = pdfStyleSelected.value

    let response = fetch(`${base}/v1/report`, {
        method: "POST",
        Accept: "application/pdf",
        body: JSON.stringify(contentDict),
        headers: {"Content-Type": "application/json"},
        responseType: "blob",
    })
    return response
}

export const getDeviceLocation = async deviceLocationId => {
    const url = `${base}/v1/devicelocations/${deviceLocationId}`
    let response = await fetch(url, options)
    let data = await response.json()
    return data.data
}

export const getDeviceLocations = async deviceIdList => {
    let url = `${base}/v1/devicelocations`
    let params = new URLSearchParams({})
    deviceIdList.forEach(id => {
        params.append("device", id)
    })
    url = url + "?" + params
    let response = await fetch(url, {...options, method: "GET"})
    let data = await response.json()
    return data.data
}

export const getBuildings = async (orgIdList) => {
    let url = `${base}/v1/buildings`
    let params = new URLSearchParams({})
    orgIdList.forEach(id => {
        params.append("organization_id", id)
    })
    url = url + "?" + params
    let response = await fetch(url, {...options, method: "GET"})
    let data = await response.json()
    return data["data"]
}

export const getBuilding = async (buidlingId) => {
    let url = `${base}/v1/buildings/`
    url = url + buidlingId
    let response = await fetch(url, {...options, method: "GET"})
    let data = await response.json()
    return data["data"]
}

export const getRooms = async (organizationIds, buildingIds) => {
    let url = `${base}/v1/rooms`
    let params = new URLSearchParams({})
    organizationIds.forEach(id => {
        params.append("organization_id", id)
    })
    buildingIds.forEach(id => {
        params.append("building_id", id)
    })
    url = url + "?" + params
    let response = await fetch(url, {...options, method: "GET"})
    let data = await response.json()
    return data["data"]
}

export const getRoom = async (roomId) => {
    let url = `${base}/v1/rooms/${roomId}`
    let response = await fetch(url, {...options, method: "GET"})
    let data = await response.json()
    return data["data"]
}

export const getOrganization = async (organizationId) => {
    let url = `${base}/v1/organizations/${organizationId}`
    let response = await fetch(url, {...options, method: "GET"})
    let data = await response.json()
    return data["data"]
}

export const getAssets = async (organizationIds, buildingIds, roomIds) => {
    let url = `${base}/v1/assets`
    let params = new URLSearchParams({})
    organizationIds.forEach(id => {
        params.append("organization_id", id)
    })
    buildingIds.forEach(id => {
        params.append("building_id", id)
    })
    roomIds.forEach(id => {
        params.append("room_id", id)
    })
    url = url + "?" + params
    let response = await fetch(url, {...options, method: "GET"})
    let data = await response.json()
    return data["data"]
}

export const getAsset = async (assetId) => {
    let url = `${base}/v1/assets/${assetId}`
    let response = await fetch(url, {...options, method: "GET"})
    let data = await response.json()
    return data["data"]
}

export const getDeviceDeployments = async (organizationIds, buildingIds, roomIds, deviceIds,
                                           startTime, endTime) => {
    let url = `${base}/v1/devicedeployments`
    let params = new URLSearchParams({})

    organizationIds.forEach(id => {
        params.append("organization_id", id)
    })
    buildingIds.forEach(id => {
        params.append("building_id", id)
    })
    roomIds.forEach(id => {
        params.append("room_id", id)
    })
    deviceIds.forEach(id => {
        params.append("device_id", id)
    })
    if (startTime) {
        params.append("start_time", startTime)
    }
    if (endTime) {
        params.append("end_time", endTime)
    }
    url = url + "?" + params
    let response = await fetch(url, {...options, method: "GET"})
    let data = await response.json()
    return data["data"]
}

export const getDeviceDeployment = async (deviceDeploymentId) => {
    let url = `${base}/v1/devicedeployments/${deviceDeploymentId}`
    let response = await fetch(url, {...options, method: "GET"})
    let data = await response.json()
    return data["data"]
}

export const getActiveModelTemplates = async () => {
    let url = `${base}/v1/ml/templates`
    let params = new URLSearchParams({
        active_at: Math.floor(new Date().getTime() / 1000),
        include_devices: 0
    })
    url = url + "?" + params
    let response = await fetch(url, {...options, method: "GET"})
    let data = await response.json()
    return data.data
}

export const getClassifications = async (
    deviceId,
    startTime,
    endTime,
    model
) => {
    let url = `${base}/v1/classifications`

    let params = new URLSearchParams({
        device: deviceId,
        start: Math.floor(startTime.getTime() / 1000),
        end: Math.floor(endTime.getTime() / 1000),
        model: model ? model : null,
    })

    url = url + "?" + params

    let response = await fetch(url, {
        method: "GET",
        credentials: "include",
    })
    let data = await response.json()
    return data.data
}

export const getAnomalyDetectionResults = async (
    deviceId,
    startTime,
    endTime,
    model_template_ids,
    sort = "",
    limit = 10000
) => {
    let baseurl = `${base}/v1/anomalydetectionresult`


    let params = new URLSearchParams({
        device: deviceId,
        start: Math.floor(startTime.getTime() / 1000),
        end: Math.floor(endTime.getTime() / 1000),
        limit: limit,
    })

    model_template_ids.forEach(id => params.append('model_template_id', id))

    if (sort !== "") {
        params.append("sort", sort)
    }

    let url = baseurl + "?" + params

    let response = await fetch(url, {
        method: "GET",
        ...options,
    })
    let data = await response.json()
    let returnData = data.data

    let pagination = data.pagination
    let total = pagination.total
    let offset = pagination.offset + limit
    while(total > offset){
        params.set("offset", offset)

        url = baseurl + "?" + params

        let response = await fetch(url, {
            method: "GET",
            ...options,
        })
        let moreData = await response.json()
        returnData.push(...moreData.data)
        offset += limit
    }


    return returnData
}

export const getActiveAnomalyClassifications = async (
    deviceId,
    startTime,
    endTime,
    sort = ""
) => {
    let url = `${base}/v1/anomalydetectionresult`

    const activeTemplates = await getActiveModelTemplates()

    let params = new URLSearchParams({
        device: deviceId,
        start: Math.floor(startTime.getTime() / 1000),
        end: Math.floor(endTime.getTime() / 1000),
    })

    if( sort !== ""){
    params.append("sort", sort)
  }activeTemplates.forEach(t => {
        params.append("model_template_id", t.id)
    })

    url = url + "?" + params

    let response = await fetch(url, {
        method: "GET",
        credentials: "include",
    })
    let data = await response.json()
    return data.data
}

export const getDeviceCondition = async (
    deviceIds,
    organizationIds,
    start_time,
    end_time
) => {
    let url = `${base}/v1/device_condition`

    let params = new URLSearchParams({
        start_time: Math.floor(start_time.getTime() / 1000),
        end_time: Math.floor(end_time.getTime() / 1000),
    })
    deviceIds.forEach(deviceId => params.append("device", deviceId))
    organizationIds.forEach(orgId => params.append("organization", orgId))

    url = url + "?" + params

    let response = await fetch(url, {
        method: "GET",
        credentials: "include",
    })

    let data = await response.json()
    return data.data
}

export const getDeviceConditionCached = async () => {
    let url = `${base}/v1/device_condition_cached`

    let response = await fetch(url, {...options, method: "GET"})
    let data = await response.json()
    return data["data"]

}

export const getAlarm = async (alarm_id) => {
    let url = `${base}/v1/alarm/${alarm_id}`
    let response = await fetch(url, {...options, method: "GET"})
    let data = await response.json()
    return data["data"]
}

export const getAlarms = async (device_ids, org_ids, start_time, end_time, customer_visible) => {
  let url = `${base}/v1/alarm`;
  const params = new URLSearchParams({})
  if (start_time) {
      params.append("start_time", Math.floor(start_time.getTime() / 1000))
  }
  if (end_time ){
      params.append("end_time", Math.floor(end_time.getTime() / 1000))
  }

  if(customer_visible !== undefined){
      params.append("customer_visible", customer_visible)
  }

  device_ids.forEach(deviceId => params.append("device_id", deviceId))
  org_ids.forEach(orgId => params.append("organization_id", orgId))
  url = url + "?" + params
  const response = await fetch(url, {...options, method: 'GET'});
  const data = await response.json()
  return data['data'];
}

export const getAlarmsByDeviceId = async (device_id, customer_visible) => {
    let url = `${base}/v1/alarm`;
    const params = new URLSearchParams({device_id: [device_id]})
    if(customer_visible !== undefined){
        params.append("customer_visible", customer_visible)
    }
    url = url + "?" + params
    const response = await fetch(url, {...options, method: 'GET'});
    const data = await response.json()
    return data['data'];
}

export const postAlarm = async (alarm) => {
    let url = `${base}/v1/alarm`
    options.headers = new Headers({'content-type': 'application/json'})
    let body = JSON.stringify(alarm)
    let response = await fetch(url, {...options, method: "POST", body: body})
    let data = await response.json()
    return data["data"]
}

export const putAlarm = async (alarm_id, alarm) => {
    let url = `${base}/v1/alarm/${alarm_id}`
    options.headers = new Headers({'content-type': 'application/json'})
    let body = JSON.stringify(alarm)
    let response = await fetch(url, {...options, method: "PUT", body: body})
    let data = await response.json()
    return data["data"]
}

export const publishAlarm = async (alarm_id) => {
    let url = `${base}/v1/alarm/${alarm_id}/publish`
    options.headers = new Headers({'content-type': 'application/json'})
    let response = await fetch(url, {...options, method: "POST"})
    return response
}

export const getAlarmFeedback = async (alarm_id) => {
    let url = `${base}/v1/alarmfeedback`
    //Todo: Open for other search params
    let params = new URLSearchParams({alarm_id: alarm_id})
    url = url + "?" + params
    let response = await fetch(url, {...options, method: "GET"})
    let data = await response.json()
    return data["data"]
}

export const postAlarmFeedback = async (alarm_id, rating, comment) => {
    let url = `${base}/v1/alarmfeedback`
    options.headers = new Headers({'content-type': 'application/json'})
    let body = JSON.stringify({"alarm_id": alarm_id, "rating": rating, "comment": comment})
    let response = await fetch(url, {...options, method: "POST", body: body})
    let data = await response.json()
    return data["data"]
}

