import Titlebar from "../../../components/Titlebar"
import PhotoUpload from "../components/PhotoUpload"
import { useEffect, useState } from "react"
import { TempState } from "../../../types/componentTypes"
import { Building, Room, RoomPosition } from "../../../types/dataTypes"
import { postRoom, updateRoom } from "../../../api/api-ts"
import TextInput from '../components/TextInput';
import { 
    useNavigate, 
    useLocation, 
    useParams 
} from 'react-router-dom'
import Button from "../../../components/Button"

type InstallationRoomProps = {
    state: TempState
    building?: Building
}

export default function InstallationRoom({ state, building }: InstallationRoomProps) {
    const navigate = useNavigate()
    const params = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search)

    const [headline, setHeadline] = useState('Register Building');
    const [subheadline, setSubheadline] = useState('');

    const [selectedImage, setSelectedImage] = useState<string | null>(null)

    const [roomName, setRoomName] = useState<string | undefined>("")
    const [roomId, setRoomId] = useState<string | null>("");
    const [floorNumber, setFloorNumber] = useState<string | undefined>("")
    const [buildingId, setBuildingId] = useState<string | undefined>("")
    const [buildingIdError, setBuildingIdError] = useState<string>("")

    const [roomNameError, setRoomNameError] = useState<string>("")
    const [floorNumberError, setFloorNumberError] = useState<string>("")

    const [submitted, setSubmitted] = useState(false);


    const handlePhotoChange = (imageUrl: string | null) => {
        setSelectedImage(imageUrl)
    }

    useEffect(() => {
        const fetchData = () => {
          const queryParam_building = queryParams.get("buildingId")
          const param_room = params.room_id;
          if (queryParam_building) {
            setHeadline("Register Room")
            setBuildingId(queryParam_building);
          }
          if (param_room) {
            setHeadline("Edit Room")
            setRoomId(param_room)
          }
          if (buildingId) {
            const buildingDetails = state.buildings?.find(building => building.id === buildingId)
            setSubheadline(`in building: ${buildingDetails?.name}`) 
          }
          if (roomId) {
            const room = state.rooms?.find(room => room.id === roomId)
            setRoomName(room?.name ?? room?.nice_name)
            setFloorNumber(room?.floor)
            setBuildingId(room?.building_id)
          }
          if(!buildingId) {
            setBuildingIdError('Building is not selected.')
          } else {
            setBuildingIdError('')
          }

        };
        fetchData();
    }, [state.buildings, state.rooms, buildingId, roomId]);

    const handleSave = async () => {
        let nameError, floorError, buildingError = false

        if(!buildingId) {
            setBuildingIdError('Building is not selected.')
            buildingError = true
        } else {
            setBuildingIdError('')
        }

        if (!roomName?.trim()) {
            setRoomNameError('Room name is required.')
            nameError = true
        } else{
            setRoomNameError('')
        }

        if (!floorNumber?.trim()) {
            setFloorNumberError('Room floor is required.')
            floorError = true
        } else {
            setFloorNumberError('')
        }

        if (nameError || floorError || buildingError) {return}

        if (!roomId && roomName && floorNumber && buildingId) {
            //@ts-ignore
            const room: Room = {
                name: roomName,
                nice_name: roomName,
                id: "",
                floor: floorNumber,
                building_id: buildingId
            }
            try {
                const responseData = await postRoom(room)
                console.log("Room created successfully: ", responseData)
                navigate(`/installation/?roomId=${responseData.id}`)
            } catch (error) {
                console.error("Error creating room", error)
                throw error
            } 
        } else if(roomId && roomName && floorNumber && buildingId) {
            //@ts-ignore
            const room: Room = {
                name: roomName,
                nice_name: roomName,
                id: roomId,
                floor: floorNumber,
                building_id: buildingId
            }
            try {
                const result = await updateRoom(room)
                console.log(result)
                navigate(`/installation/?roomId=${result.id}`)
            } catch (e) {
                console.error('Error creating room:', e)
            }
        }
    }

    const navigateToOverview = () => {
        navigate(`/installation/?buildingId=${buildingId}`);
    }

    return (
        <div className="tw-h-full tw-px-4 tw-py-1">
            <Titlebar headline={`${headline}`} subHeadline={`${subheadline}`}/> 
            <div className="tw-flex tw-flex-col tw-gap-4 tw-py-1">
                {!roomId ? (
                <>
                {buildingIdError && <div style={{ color: 'red' }}>{buildingIdError}</div>}
                <TextInput
                    id="roomName"
                    label="Room Name"
                    value={roomName}
                    onChange={(e) => setRoomName(e.target.value)}                    placeholder="Enter room name"
                    validate={value => value ? undefined : 'Room name is required.'}
                />
                {roomNameError && <div style={{ color: 'red' }}>{roomNameError}</div>}
                <TextInput
                    id="floorNumber"
                    label="Floor Number"
                    value={floorNumber}
                    onChange={(e) => setFloorNumber(e.target.value)}
                    placeholder="Enter floor number"
                    validate={value => value ? undefined : 'Floor number is required.'}
                />
                {floorNumberError && <div style={{ color: 'red' }}>{floorNumberError}</div>}
                <PhotoUpload />
                <div className="tw-flex tw-justify-between">
                    <Button onClick={navigateToOverview} type="button" size="medium">Cancel</Button>
                    <Button onClick={handleSave} type="button" size="medium">Save</Button>
                </div>
                </>
                ) : (
                <>
                <TextInput
                id="roomName"
                label="Room Name"
                value={roomName}
                onChange={(e) => setRoomName(e.target.value)}                    placeholder="Enter room name"
                validate={value => value ? undefined : 'Room name is required.'}
                />
                <TextInput
                    id="floorNumber"
                    label="Floor Number"
                    value={floorNumber}
                    onChange={(e) => setFloorNumber(e.target.value)}
                    placeholder="Enter floor number"
                    validate={value => value ? undefined : 'Floor number is required.'}
                />
                <Button onClick={handleSave} type="button" size="medium">Save Room</Button>
                </>)}
            </div>
        </div>
    );
};
