import React, { useEffect, useRef, useState } from "react";
import { addSingleMarkers } from "./Markers";

const DEFAULT_ZOOM = 13;

export const GoogleMaps = ({
  onLocationChange,
}: {
  className?: string;
  onLocationChange: (coordinates: google.maps.LatLngLiteral) => void;
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [renderMap, setRenderMap] = useState(false);
  const [collapseMap, setCollapseMap] = useState(true);
  const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);
  const [marker, setMarker] = useState<google.maps.Marker | null>(null);

  useEffect(() => {
    setRenderMap(true);
    setCollapseMap(false);
  },[]);

  useEffect(() => {
    if (ref.current && renderMap) {
      const map = new window.google.maps.Map(ref.current, {
        zoom: DEFAULT_ZOOM,
      });

      // Displays single markers on map when called
      addSingleMarkers({ map, onLocationChange });

      // Add click event listener to replace marker
      map.addListener("click", (event: google.maps.MapMouseEvent) => {
        if (mapInstance && marker) {
          const clickedLatLng = event.latLng?.toJSON();
            if (clickedLatLng) {
              marker.setPosition(event.latLng);
              onLocationChange({
                lat: clickedLatLng.lat,
                lng: clickedLatLng.lng,
              });
            }
        }
      });

      setMapInstance(map);
    }
  }, [renderMap]);

  return (
    <div className="tw-w-full">
      {!collapseMap &&
      <div className="tw-py-3">
        <div ref={ref} style={{margin: "2rem", height: "20em" }}/>
      </div>
      }
    </div>
  );
};



