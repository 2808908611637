import React from "react"
import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router";
import ReactPaginate from "react-paginate";
import AlarmSuggestion from "./AlarmSuggestion";
import {AlarmWithInfo} from "../../../types/componentTypes";

const AlarmSuggestionPage = ({alarmSuggestions, anomalyProbabillityVisible}: {
  alarmSuggestions: AlarmWithInfo[],
  anomalyProbabillityVisible: boolean
}) => {
  return <div className="tw-w-full">
    {alarmSuggestions.map(dc => dc.device ? <AlarmSuggestion device={dc.device} alarmSuggestion={dc} key={dc.alarm.id}
                                                 anomalyProbabillityVisible={anomalyProbabillityVisible}/> : "")}
  </div>
}


export default function PaginatedAlarmSuggestions({
                                          devicesPerPage,
                                          alarmSuggestions, anomalyProbabillityVisible
                                        }: {
  devicesPerPage: number,
  alarmSuggestions: AlarmWithInfo[],
  anomalyProbabillityVisible: boolean
}) {

  // We start with an empty list of items.
  const [currentAlarmSuggestions, setCurrentAlarmSuggestions] = useState<AlarmWithInfo[]>([]);
  const [pageCount, setPageCount] = useState<number>();
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState<number>();

  const location = useLocation()
  const navigate = useNavigate()

  const queryParams = new URLSearchParams(location.search)

  useEffect(() => {
    if (alarmSuggestions.length > 0 && devicesPerPage > 0) {
      setPageCount(Math.ceil(alarmSuggestions.length / devicesPerPage));
    }
  }, [alarmSuggestions])

  useEffect(() => {
    if (pageCount !== undefined && pageCount > 0) {

      const urlPage = queryParams.get('page')
      let page = Number(urlPage)
      if (urlPage === undefined || urlPage === null || isNaN(page)) {
        handleChangePage(0)
      }
      if (page > 0 && (page - 1) !== currentPage) {
        handleChangePage(page - 1)
      }
      if (page > pageCount){
        handleChangePage(0)
      }

    }
  }, [pageCount])

  useEffect(() => {
    setCurrentAlarmSuggestions(alarmSuggestions.slice(itemOffset, itemOffset + devicesPerPage))
  }, [itemOffset, currentPage, alarmSuggestions])


  // Invoke when user click to request another page.
  const handlePageClick = (event: { selected: number }) => {
    handleChangePage(event.selected)
  };

  const handleChangePage = (page: number) => {
    const newOffset = page * devicesPerPage % alarmSuggestions.length;
    setItemOffset(newOffset);
    setCurrentPage(page)
    navigate("/suggestiondashboard?page=" + (page + 1))
  }

  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-w-full tw-mt-5">
      <ReactPaginate
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount ?? 0
        }
        previousLabel="< previous"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="tw-flex"
        activeClassName="active"
        // @ts-ignore
        renderOnZeroPageCount={null}
        forcePage={currentPage}
        onPageActive={e => console.log(e)}
      />
      <AlarmSuggestionPage
        alarmSuggestions={currentAlarmSuggestions}
        anomalyProbabillityVisible={anomalyProbabillityVisible}/>
      <ReactPaginate
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount ?? 0
        }
        previousLabel="< previous"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="tw-flex"
        activeClassName="active"
        // @ts-ignore
        renderOnZeroPageCount={null}
        forcePage={currentPage}
        onPageActive={e => console.log(e)}
      />
    </div>
  );
}