import { sortTable } from "../../functions/helpers"
import { Building, DeviceDeployment, Room } from "../../types/dataTypes"
import InformationTooltip from "../InformationTooltip"
import { Link } from "react-router-dom"
import { ReactNode } from "react"

const dynamicPadding = "tw-px-2 lg:tw-px-6"

type GenericTableProps<T> = {
  id: string
  columns: string[],
  items: T[],
  itemToColumns: (item: T) => JSX.Element[],
  itemToColumnStyle?: (item: T) => string[]
}

export function GenerticTable<T>({ id, columns, items, itemToColumns,
                                 itemToColumnStyle}: GenericTableProps<T>) {


  return (
    <div>
      <div className="">
        <table className="tw-w-full tw-text-left" id={id}>
          <thead
            className="tw-text-typography-muted tw-border-b tw-border-stroke-off-white tw-cursor-pointer">
          <tr>
            {columns.map((value, index) => {
              return (
                <th
                  key={"generic-column-header-" + index}
                  scope="col"
                  className={`${dynamicPadding} md:tw-pl-6 tw-py-3 tw-font-light hover:tw-underline ${index === 0 ? "tw-hidden md:tw-table-cell" : ""}`}
                  onClick={() => sortTable(index, id)}
                >
                  {value}
                </th>
              )
            })}
          </tr>
          </thead>
          <tbody>
          {items.map((item, index) => {
            return (
              <tr
                key={id + "-item-row-" + index}
                className={`tw-bg-white hover:tw-bg-secondary-darker-off-white hover:tw-bg-opacity-30 tw-border-b tw-border-stroke-off-white last:tw-border-none`}
              >
                {itemToColumns(item).map((column, colIdx) => {
                  let colStyles = itemToColumnStyle !== undefined ? itemToColumnStyle(item) : undefined
                    return (
                      <td
                        key={id + "-row-content-col" + colIdx}
                        className={`${dynamicPadding} md:tw-pl-6 tw-py-3 
                        ${ colIdx === 0 ? " tw-hidden md:tw-flex" : " tw-break-all lg:tw-break-words"} 
                        ${colStyles !== undefined && colStyles[colIdx]}`}
                      >
                        {column}
                      </td>
                    )
                  })
                  }
              </tr>
            )
          })}
          </tbody>
        </table>
      </div>
    </div>
  )
}