import React, { useEffect, useState } from "react"

type DropdownWrapperProps = {
  subtitle?: string
  title: string | undefined
  titleIcon?: React.ReactNode
  children?: React.ReactNode
  defaultOpen: boolean
  border?: "yes" | "no",
  disabled?: boolean
}

export default function DropdownWrapper({
  subtitle,
  title,
  titleIcon,
  children,
  defaultOpen,
  border = "yes",
  disabled,
}: DropdownWrapperProps) {
  const [open, setOpen] = useState<boolean>(defaultOpen)
  const [isDisabled, setIsDisabled] = useState<boolean | undefined>(disabled)

  useEffect(() => {
    setIsDisabled(disabled)
    setOpen(defaultOpen)
  }, [disabled, defaultOpen])

  return (
    <main
      className={`tw-relative tw-overflow-x-auto tw-bg-typography-light lg:tw-mx-10 tw-mx-0 ${
        border === "no" ? "tw-border-none" : "tw-border tw-border-stroke-off-white tw-mb-5 tw-py-2 tw-rounded-normal"
      }`}
    > 
      <section>
        <div className="tw-tabs">
          <div className="tw-overflow-hidden">
            <div className="tw-relative">
              <header
                onClick={() => setOpen(!open)}
                className={`tw-flex tw-justify-between tw-items-center tw-p-5 tw-pl-8 tw-pr-8 tw-cursor-pointer tw-select-none ${
                  open ? "after:tw-content-none after:tw-rotate-90" : ""
                }`}
              >
                <span className=" tw-leading-24 tw-justify-left">
                <span className={"tw-flex-row tw-text-10 tw-text-typography-muted"}>{subtitle}</span>
                  <div className={"tw-flex tw-text-24 tw-font-bold"}>
                    {titleIcon &&  <span className={"tw-flex-row tw-mr-3"}>{titleIcon}</span> }
                    <span className={"tw-flex-row"}>{title}</span>
                  </div>
                </span>
                <div>
                  {!isDisabled && (
                    <img
                      src="icon-chevron.svg"
                      alt="chevron icon"
                      className={`${open ? "tw-rotate-180" : ""}`}
                    />
                  )}
                </div>
              </header>
              <div
                className={`${
                  open
                    ? ""
                    : "tw-max-h-0 tw-transition-all tw-duration-500"
                }`}
              >
                {children}
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}
