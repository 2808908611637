import {
  getAlarmsByDeviceId, getAnomalyDetectionResults, postAlarm,
  publishAlarm, putAlarm,
} from "../../../api/api"
import {
  Alarm as AlarmModel, Annotation,
  AnomalyGraphDataMap, Asset,
  Building,
  Device,
  ModelTemplate,
  Room, UserAccount, AnnotationFormData,
} from "../../../types/dataTypes"
import React, { ChangeEvent, ChangeEventHandler, useEffect, useState } from "react"
import Button from "../../../components/Button"
import TrafficLight from "../../../components/TrafficLight"
import SectionWrapper from "../../../components/SectionWrapper"
import {
  SimpleGraphDates,
  simpleGraphTimeConverter,
} from "../../../components/AnomalyGraph/Components/SimpleDateSelect"
import { AlarmWithInfo } from "../../../types/componentTypes"
import AnomalyGraph from "../../../components/AnomalyGraph"
import { getAnomalyDetectionResultParallel, getModelTemplates, postAnnotation } from "../../../api/api-ts"
import { Link } from "react-router-dom"
import { Data } from "plotly.js"
import { formatAlarmText, formatDate, createPreviousAlarmXYAndText } from "../../../components/AnomalyGraph"
import AnnotationForm from "../../../components/AnnotationForm"
import alarm from "../../Alarm"
import { chunk } from "../../../functions/chunk"

enum AlarmSuggestionState {
  Unhandeled,
  InConfirm,
  InReject,
  Confirmed,
  Rejected,
  Error
}

export default function AlarmSuggestion({
                                          device, alarmSuggestion, anomalyProbabillityVisible,
                                        }: {
  device: Device,
  alarmSuggestion: AlarmWithInfo,
  anomalyProbabillityVisible: boolean
}) {

  const [anomalyGraphDataMap, setAnomalyGraphDataMap] = useState<AnomalyGraphDataMap>({})
  const [modelTemplates, setModelTemplates] = useState<ModelTemplate[]>([])
  const [existingAlarms, setExistingAlarms] = useState<AlarmModel[]>([])
  const [selectedTime, setSelectedTime] = useState<SimpleGraphDates>("Last 30 days")
  const [alarmSuggestionState, setAlarmSuggestionState] = useState<AlarmSuggestionState>(AlarmSuggestionState.Unhandeled)
  const [annotationFormData, setAnnotationFormData] = useState<AnnotationFormData>({
    description: "",
    tags: [],
  })
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setLoading(true)
    const fetchAndSetAnomalyDetectionResults = async () => {
      const endTime = new Date()
      const startTime = new Date(endTime.getTime() - simpleGraphTimeConverter(selectedTime) * 24 * 60 * 60 * 1000)
      let modelTemplates: ModelTemplate[] = await getModelTemplates([device.id], [], [], undefined, undefined, undefined, startTime, false)
      modelTemplates = modelTemplates.filter(mt => mt.enabled && ((mt.end_time === undefined || mt.end_time === null) || mt.end_time > startTime.getTime() / 1000))
      let anomalyGraphDataResult: AnomalyGraphDataMap = await getAnomalyDetectionResultParallel(4, modelTemplates, device.id, startTime, endTime)
      setModelTemplates(modelTemplates)
      setAnomalyGraphDataMap(anomalyGraphDataResult)
      setLoading(false)
    }
    fetchAndSetAnomalyDetectionResults()
  }, [selectedTime])


  useEffect(() => {
    const fetchAndSetExistingAlarms = async () => {
      const data: AlarmModel[] = await getAlarmsByDeviceId(device.id, 1)
      setExistingAlarms(data)
    }
    fetchAndSetExistingAlarms()
  }, [])

  function constructAlarmLink(building?: Building, room?: Room, assets?: Asset[]) {
    let base = "/?"
    base += `${building !== undefined ? "orgId=" + building.organization_id + "&buildingId=" + building.id : ""}`
    base += `&${room !== undefined ? "roomId=" + room.id : ""}`
    base += `&${assets !== undefined && assets.length === 1 ? "assetId=" + assets[0].id : ""}`
    return base
  }

  function addSuggestedAlarmMarkerToPlot(plotData: Data[], yLowerRange: number, yUpperRange: number) {
    const yLowerRangeWithMargins = yLowerRange - (yUpperRange - yLowerRange) * 0.25
    const yUpperRangeWithMargins = yUpperRange + (yUpperRange - yLowerRange) * 0.25

    let data: Data = {
      ...createPreviousAlarmXYAndText(alarmSuggestion.alarm, 100, yLowerRangeWithMargins, yUpperRangeWithMargins),
      legendgroup: "Suggested Alarm",
      mode: "lines+markers",
      marker: { color: "rgba(255, 255, 255, 0)", size: 16 },
      showlegend: true,
      name: "Suggested Alarm",
      line: {
        dash: "dash",
        width: 4,
        color: "blue",
      },
      meta: {
        "alarm_id": alarmSuggestion.alarm.id,
      },
    }

    plotData.push(data)
  }

  const createAndPostAnnotation = async (tags: string[], description: string, anomaly: "YES" | "NO" | "MAYBE", successState: AlarmSuggestionState) => {
    let annotation: Annotation = {
      device_id: alarmSuggestion.alarm.device_id,
      end_time: alarmSuggestion.alarm.timestamp,
      start_time: alarmSuggestion.alarm.timestamp,
      alarm_id: alarmSuggestion.alarm.id,
      anomaly: anomaly,
      tags: tags.concat(["task_alarm_suggestion_review", "alarm_suggestion_review"]),
      description: description,
    }
    console.log(annotation)
    try {
      let result = await postAnnotation(annotation)
      if (successState != AlarmSuggestionState.Confirmed) {
        let alarmResult = await putAlarm(alarmSuggestion.alarm.id, { "resolved": true })
      }
      setAlarmSuggestionState(successState)
    } catch (e) {
      setAlarmSuggestionState(AlarmSuggestionState.Error)
    }
  }

  function renderState(alarmSuggestionState: AlarmSuggestionState): JSX.Element {
    switch (alarmSuggestionState) {
      case AlarmSuggestionState.Unhandeled:
        return (
          <div className="tw-space-x-1">
            <Button type="button" size="large" variant="secondary"
                    styles={"tw-max-w-xs tw-bg-red-200"} onClick={() => {
              setAlarmSuggestionState(AlarmSuggestionState.InReject)
            }}>
              <span className={"tw-text-primary-main-black"}>Don't Send Alarm</span>
            </Button>
            <Button type="button" size="large" variant="secondary"
                    styles={"tw-max-w-xs"} onClick={() => {
              setAlarmSuggestionState(AlarmSuggestionState.InConfirm)
            }}>
              <span className={"tw-text-primary-main-black"}>Send Alarm</span>
            </Button>
          </div>
        )
      case AlarmSuggestionState.Confirmed:
        return (
          <div className="tw-flex-col tw-items-center">
            <img
              src="icon-check.svg"
              alt="Confirmed"
              className="tw-w-[100px] tw-m-auto group-hover:tw-hidden" />
            <p>Alarm Published</p>
          </div>
        )
      case AlarmSuggestionState.InConfirm:
        return (
          <div className="tw-items-center tw-space-x-1">
            <Button type="button" size="large" variant="secondary"
                    styles={"tw-max-w-xs tw-bg-green-200"} onClick={() => {
              publishAlarm(alarmSuggestion.alarm.id)
              createAndPostAnnotation([], "", "YES", AlarmSuggestionState.Confirmed)
            }}>
              <span>Yes, i am sure!</span>
            </Button>
            <Button type="button" size="large" variant="secondary"
                    styles={"tw-max-w-xs tw-bg-red-200"} onClick={() => {
              setAlarmSuggestionState(AlarmSuggestionState.Unhandeled)
            }}>
              <span>Cancel</span>
            </Button>
          </div>
        )
      case AlarmSuggestionState.InReject:
        return (
          <AnnotationForm title="Please Annotate The Alarm" annotationFormData={annotationFormData}
                          setAnnotationFormData={setAnnotationFormData}
                          onSubmit={() => createAndPostAnnotation(annotationFormData.tags,
                            annotationFormData.description, annotationFormData.anomaly!, AlarmSuggestionState.Rejected)}
                          onCancel={() => setAlarmSuggestionState(AlarmSuggestionState.Unhandeled)}
          />
        )
      case AlarmSuggestionState.Rejected:
        return (
          <div className="tw-flex-col tw-items-center tw-justify-items-center">
            <img
              src="icon-cross.svg"
              alt="Rejected"
              className="tw-w-[100px] tw-m-auto group-hover:tw-hidden" />
            <p>This alarm was not sent, but was successfully annotated</p>
          </div>
        )
      case AlarmSuggestionState.Error:
        // XXX: This is bad error handling, update if needed
        return (
          <div>
            Error, could not post data, please reload page and try again, or contact developer.
          </div>
        )
      default:
        return (<div>Loading State</div>)
    }
  }

  return (
    <SectionWrapper styles={"tw-border tw-ml-10 tw-p-5 tw-my-5 tw-mr-10"} key={device.id}>
      <div className="tw-flex tw-flex-col tw-w-full tw-items-center">
        <div className={"tw-flex tw-flex-row tw-text-2xl tw-space-x-8"}>
          <TrafficLight status={alarmSuggestion.alarm.alarm_type === "A" ? "CRITICAL" : "WARNING"} />
          <p>Device: {device.serial ?? "Unknown"}</p>
          <p>Organization: {alarmSuggestion.organization ? alarmSuggestion.organization.name : "Unknown"}</p>
          <p>Building: {alarmSuggestion.building ? alarmSuggestion.building.name ?? alarmSuggestion.building.address : "Unknown"}</p>
          <p>Asset: {alarmSuggestion.deviceDeployment?.assets !== undefined && alarmSuggestion.deviceDeployment?.assets.length > 0 ? alarmSuggestion.deviceDeployment?.assets[0].name : "Unknown"}</p>
          <p>Room: {alarmSuggestion.room ? alarmSuggestion.room.name ?? alarmSuggestion.room.nice_name : "Unknown"}</p>
        </div>
        <>
          <div className="tw-w-full">
            <AnomalyGraph className="tw-w-full" device={device} modelTemplates={modelTemplates}
                          anomalyGraphDataMap={anomalyGraphDataMap}
                          alarms={existingAlarms}
                          onUpdateGraphTime={graphTime => setSelectedTime(graphTime)}
                          selectedGraphTime={selectedTime}
                          showProbability={true} probabilityVisible={anomalyProbabillityVisible}
                          addCustomPlotData={addSuggestedAlarmMarkerToPlot} loading={loading} />
          </div>
          <div className="tw-w-full tw-ml-10 tw-mt-10">
            <div className="tw-grid tw-grid-cols-3 tw-gap-4">
              <div className="tw-text-gray-500 tw-font-bold">
                Description
              </div>
              <div className="tw-col-start-2 tw-col-end-4 lg:tw-col-end-3 tw-text-lg">
                {alarmSuggestion.alarm.description}
              </div>
              <div className="tw-col-start-1 tw-col-end-4 lg:tw-col-end-3 tw-border-b tw-border-gray-300" />
              <div className="tw-col-start-1 tw-text-gray-400">Location</div>
              <div className="tw-col-start-2 tw-col-end-4 lg:tw-col-end-3">
                <table className="tw-table-fixed tw-w-full tw-text-sm tw-text-left tw-text-primary-main-black">
                  <tbody>
                  <tr>
                    <th>Building</th>
                    <td><Link
                      to={constructAlarmLink(alarmSuggestion.building)}>{alarmSuggestion.building ? (alarmSuggestion.building.name ?? alarmSuggestion.building.address) : "Loading"} </Link>
                    </td>
                  </tr>
                  <tr>
                    <th>Room</th>
                    <td><Link
                      to={constructAlarmLink(alarmSuggestion.building, alarmSuggestion.room)}>{alarmSuggestion.room ? (alarmSuggestion.room.nice_name ?? alarmSuggestion.room.name) : "Loading"} </Link>
                    </td>
                  </tr>
                  <tr>
                    <th>Assets</th>
                    <td><Link
                      to={constructAlarmLink(alarmSuggestion.building, alarmSuggestion.room, alarmSuggestion.deviceDeployment?.assets)}>{alarmSuggestion.deviceDeployment?.assets ? (alarmSuggestion.deviceDeployment?.assets.map((a, index) => {
                        return ((index != 0 ? ", " : "") + (a.nice_name ?? a.name))
                      },
                    )) : "Loading"}</Link></td>
                  </tr>
                  <tr>
                    <th>Device</th>
                    <td>{device ? (device.nickname ?? device.serial) : alarmSuggestion.alarm.device_id}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div className="tw-col-start-1 tw-col-end-4 lg:tw-col-end-3 tw-border-b tw-border-gray-300" />
              <div className="tw-col-start-1 tw-text-gray-400">Information</div>
              <div className="tw-col-start-2 tw-col-end-4 lg:tw-col-end-3">
                <table className="tw-table-fixed tw-w-full tw-text-sm tw-text-left tw-text-primary-main-black">
                  <tbody>
                  <tr>
                    <th>Alarm Id</th>
                    <td>{alarmSuggestion.alarm.id}</td>
                  </tr>
                  <tr>
                    <th>Alarm Type</th>
                    <td className="tw-flex">
                      <div className="tw-inline-flex">{alarmSuggestion.alarm.alarm_type + "-Alarm"}</div>
                      <TrafficLight status={alarmSuggestion.alarm.alarm_type === "A" ? "CRITICAL" : "WARNING"} /></td>
                  </tr>
                  <tr>
                    <th>Time</th>
                    <td>{new Date(alarmSuggestion.alarm.timestamp * 1000).toLocaleString()} </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="tw-mt-10">
            {renderState(alarmSuggestionState)}
          </div>
        </>
      </div>
    </SectionWrapper>
  )
}