import React, {  
  useState,
  useEffect
} from 'react';
import Titlebar from "../../../components/Titlebar";
import { TempState } from "../../../types/componentTypes";
import { getBuilding } from "../../../api/api";
import { 
  updateBuilding,
  postBuilding,
  getOrganizations,
 } from '../../../api/api-ts';
import { 
  Building, 
  Organization 
} from "../../../types/dataTypes";
import SearchSuggestion from '../components/InstallationSearchBar';
import { 
  useNavigate, 
  useLocation, 
  useParams
} from 'react-router-dom';
import Button from "../../../components/Button";
import { GoogleMapsWrapper } from '../components/GoogleMaps/GoogleMapsWrapper';
import { GoogleMaps } from '../components/GoogleMaps/GoogleMaps';
import TextInput from '../components/TextInput';

type InstallationBuildingProps = {
  state: TempState;
}

export default function InstallationBuilding({state}: InstallationBuildingProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search)
  const params = useParams();

  const [headline, setHeadline] = useState('Register Building');
  const [subheadline, setSubheadline] = useState('');

  const [Address, setAddress] = useState('');
  const [Zipcode, setZipCode] = useState('');
  const [City, setCity] = useState('');
  const [fullAddress, setFullAddress] = useState<string | undefined>('');
  const [latitude, setLatitude] = useState<number | undefined>();
  const [longitude, setLongitude] = useState<number | undefined>();
  const [Country, setCountry] = useState('Norge');
  const [name, setName] = useState<string | undefined>('');
  const [site, setSite] = useState<string | undefined>('');
  const [organizationId, setOrganizationId] = useState('');

  const [buildingId, setBuildingId] = useState('');

  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const queryParam_organization = queryParams.get("organizationId");
    const param_building = params.building_id;
    if (queryParam_organization) {
      setOrganizationId(queryParam_organization);
    }
    if (param_building) {
      setHeadline("Edit Building");
      setBuildingId(param_building);
    }
    if (organizationId){
      const organizationDetails = state.organizations?.find(org => org.id === organizationId);
      setSubheadline(`for organization: ${organizationDetails?.name}`)
      }
    if (buildingId) {
      const building = state.buildings?.find(building => building.id === buildingId);
      setName(building?.name);
      setFullAddress(building?.address);
      setSite(building?.site);
      setLongitude(building?.longitude);
      setLatitude(building?.latitude);
      setOrganizationId(building?.organization_id || '');
    }

    
  }, [state.organizations, state.buildings, organizationId, buildingId]);

  const handleSave = async () => {
      if (!buildingId) {
        const fullAddress = Address + ", " + Zipcode + " " + City + ", " + Country;
        setFullAddress(fullAddress);
        //@ts-ignore    
        const building: Building = {
            address: fullAddress,
            name: name,
            latitude: latitude, 
            longitude: longitude,
            site: site,
            organization_id: organizationId,
        };
          
        try {
            const result = await postBuilding(building);
            navigate(`/installation/?buildingId=${result.id}`);
            //navigate( '/installation/Room');
        } catch (e) {
            console.error('Error creating building:', e);
        }
      } else if (buildingId && fullAddress) {
        const building: Building = {
          id: buildingId,
          address: fullAddress,
          name: name,
          latitude: latitude, 
          longitude: longitude,
          site: site,
          organization_id: organizationId,
        };
        try {
          const result = await updateBuilding(building);
          navigate(`/installation/?buildingId=${result.id}`);
        } catch (e) {
            console.error('Error creating building:', e);
        }
      }
  };

  const handleLocationChange = (coordinates: google.maps.LatLngLiteral) => {
      setLatitude(coordinates.lat);
      setLongitude(coordinates.lng);
  };

  const navigateToPrevious = () => {
    navigate(`/installation/?organizationId=${organizationId}`);
  };

  return (
    <div className="tw-h-full tw-px-4 tw-py-1">
        <Titlebar headline={headline} subHeadline={`${subheadline}`} />
        <div className="tw-flex tw-flex-col tw-gap-4 tw-py-1">
            {!buildingId ? (
                <>
                    <TextInput label="Building name" id="buildingName" value={name} onChange={(e) => setName(e.target.value)} submitted={submitted} placeholder="Enter name" />
                    <TextInput label="Address" id="address" value={Address} onChange={(e) => setAddress(e.target.value)} submitted={submitted} placeholder="Enter address" />
                    <TextInput label="ZipCode" id="ZipCode" value={Zipcode} onChange={(e) => setZipCode(e.target.value)} submitted={submitted} placeholder="Enter zip code" />
                    <TextInput label="City" id="City" value={City} onChange={(e) => setCity(e.target.value)} submitted={submitted} placeholder="Enter city" />
                    <TextInput label="Country" id="Country" value={Country} onChange={(e) => setCountry(e.target.value)} submitted={submitted} placeholder="Enter country" />
                    <TextInput label="Site" id="Site" value={site} onChange={(e) => setSite(e.target.value)} submitted={submitted} placeholder="Enter site" />
                    <GoogleMapsWrapper>
                        <GoogleMaps onLocationChange={handleLocationChange} />
                    </GoogleMapsWrapper>
                    <div className="tw-flex tw-justify-between">
                        <Button type="button" size='medium' onClick={navigateToPrevious}>Cancel</Button>
                        <Button type="button" size='medium' onClick={handleSave}>Save</Button>
                    </div>
                </>
            ) : (
                <>
                    <TextInput label="Name" id="Name" value={name} onChange={(e) => setName(e.target.value)} submitted={submitted} placeholder="Enter name" />
                    <TextInput label="Address" id="address" value={fullAddress} onChange={(e) => setFullAddress(e.target.value)} submitted={submitted} placeholder="Enter full address" />
                    <TextInput label="Country" id="Country" value={Country} onChange={(e) => setCountry(e.target.value)} submitted={submitted} placeholder="Enter country" />
                    <TextInput label="Site" id="Site" value={site} onChange={(e) => setSite(e.target.value)} submitted={submitted} placeholder="Enter site" />
                    <Button type="button" size='medium' onClick={handleSave}>Save and back</Button>
                </>
            )}
        </div>
    </div>
);

    


};