export const addSingleMarkers = ({
  map,
  onLocationChange,
}: {
  map: google.maps.Map | null | undefined;
  onLocationChange: (coordinates: google.maps.LatLngLiteral) => void;
}) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const positionLiteral = { lat: latitude, lng: longitude };

        if (map) {
          const marker = new google.maps.Marker({
            position: positionLiteral,
            map,
          });

          map.setCenter(positionLiteral);

          onLocationChange(positionLiteral);

          // Set the initial marker
          map.addListener("click", (event : google.maps.MapMouseEvent) => {
            const clickedLatLng = event.latLng?.toJSON();
            if (clickedLatLng) {
              marker.setPosition(event.latLng);
              onLocationChange({
                lat: clickedLatLng.lat,
                lng: clickedLatLng.lng,
              });
            }
          });
        }
      },
      (error) => {
        console.error("Error getting current location:", error);
      }
    );
  } else {
    console.error("Geolocation is not supported by this browser.");
  }
};


  