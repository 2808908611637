import React from "react";
import { Wrapper } from "@googlemaps/react-wrapper";

export const GoogleMapsWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  let apiKey: string | undefined = ""
  try{
    apiKey = (window as any)._env_.GOOGLE_MAPS_API_KEY ? (window as any)._env_.GOOGLE_MAPS_API_KEY :  process.env.GOOGLE_MAPS_API_KEY;
    if (apiKey == "" || apiKey == undefined){
      apiKey =  process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ""
    } 
  } catch(error){
    console.log("Cannot get env for maps api")
  }

  if (!apiKey) {
    return <div>Cannot display the map: google maps api key missing</div>;
  }

  return <Wrapper apiKey={apiKey}>{children}</Wrapper>;
};