import React, { ReactNode, ReactElement, FC } from 'react';
import './modalPopup.css';

interface ModalProps {
    open: boolean;
    onClose: () => void;
    children: ReactElement;
    headline: string;
}

export default function ModalPopup(props: ModalProps): ReturnType<FC> {
    return (
        <div className={`${"modal"} ${props.open ? "display-block" : "display-none"}`}>
            <div className="modal-main">
                <h1>{props.headline}</h1>
                <div className="modal-body">
                    {props.children}
                </div>
            </div>
        </div>
    );
}