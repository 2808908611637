import { TempState } from "../../../types/componentTypes";
import Titlebar from "../../../components/Titlebar";
import Button from "../../../components/Button";
import DropdownWrapper from "../../../components/DropdownWrapper";


import { 
    useNavigate, 
    useLocation 
  } from 'react-router-dom';
  import React, {  
    useState,
    useEffect,
  } from 'react';
import { Asset, Building, Device, DeviceDeployment, Organization, Room} from "../../../types/dataTypes";
import GenericSelect from "../../../components/GenericSelect";
import { getOrganizations } from "../../../api/api-ts";

  type OverviewProps = {
    state: TempState;
    updateState: () => Promise<void>
}


export default function Overview({state, updateState}: OverviewProps) {

    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [isOrgOpen, setIsOrgOpen] = useState<boolean>(true);
    const [isBuildingOpen, setIsBuildingOpen] = useState<boolean>(true);
    const [isRoomOpen, setIsRoomOpen] = useState<boolean>(true);
    const [isAssetOpen, setIsAssetOpen] = useState<boolean>(true);
    

    const [isDisabledOrg, setIsDisabledOrg] = useState<boolean>(true);
    const [isDisabledBuilding, setIsDisabledBuilding] = useState<boolean>(true);
    const [isDisabledRoom, setIsDisabledRoom] = useState<boolean>(true);
    const [isDisabledAsset, setIsDisabledAsset] = useState<boolean>(true);

    const [assetId, setAssetId] = useState<string | undefined>('');
    const [asset, setAsset] = useState<Asset>();
    const [assets, setAssets] = useState<Asset[]>([]);
    const [devicedeployments, setDevicedeployments] = useState<DeviceDeployment[]>([]);
    const [devices, setDevices] = useState<Device[]>([]);

    const [roomId, setRoomId] = useState<string | undefined>('');
    const [room, setRoom] = useState<Room>();
    const [rooms, setRooms] = useState<Room[]>([]);

    const [buildingId, setBuildingId] = useState<string | undefined>('');
    const [building, setBuilding] = useState<Building>();
    const [buildings, setBuildings] = useState<Building[]>([]);

    const [organizationId, setOrganizationId] = useState<string | undefined>('');
    const [organization, setOrganization] = useState<Organization>();
    const [organizations, setOrganizations] = useState<Organization[]>([]);

    const fetchOrgs = async () => {
        try{
            setOrganizations(await getOrganizations());
        } catch (e) {
            console.error("Error fetching orgs: " + e);
        }
    }

    useEffect(() =>{
        updateState()
        return
    }, [])

    useEffect(() => {
        updateState()
        return
    }, [])

    useEffect(() => {
        const param_asset = queryParams.get("assetId");
        const param_room = queryParams.get("roomId");
        const param_building = queryParams.get("buildingId");
        const param_organization = queryParams.get("organizationId");
        if (param_asset){
            setAssetId(param_asset);
            setIsDisabledAsset(false);
        }
        if (param_room) {
            setRoomId(param_room);
            setIsDisabledAsset(false);
            setIsDisabledRoom(false);
            setIsDisabledBuilding(false);
            setIsDisabledOrg(false);

            setIsAssetOpen(false);
            setIsBuildingOpen(false);
            setIsOrgOpen(false);
        }
        if (param_building){
            setBuildingId(param_building);
            setIsDisabledBuilding(false);
            setIsDisabledOrg(false);
            setIsOrgOpen(false);
        }
        if (param_organization){
            setOrganizationId(param_organization);
            setIsDisabledOrg(false);    
        }
    },[ queryParams]);

    useEffect(() => {
        fetchOrgs();
        if (organizationId){
            const organizationDetails = state.organizations?.find(org => org.id === organizationId);
            if(organizationDetails) {
                setOrganization(organizationDetails);
                const filteredBuildings = (state.buildings ?? []).filter(building => building.organization_id === organizationId);
                setBuildings(filteredBuildings);
            }
        }
        if (buildingId){
            const buildingDetails = state.buildings?.find(building => building.id === buildingId);
            if(buildingDetails) {
                setBuilding(buildingDetails);
                setOrganizationId(buildingDetails.organization_id);

                const filteredRooms = (state.rooms ?? []).filter(room => room.building_id === buildingId);
                setRooms(filteredRooms);
            }
        }
        if(roomId){
            const roomDetails = state.rooms?.find(room => room.id === roomId);
            if (roomDetails) {
                setRoom(roomDetails);     
                setBuildingId(roomDetails.building_id);

                const filteredAssets = (state.assets ?? []).filter(asset => asset.room_id === roomId);
                setAssets(filteredAssets);
            }
        }
        if(assetId){
            const assetDetails = state.assets?.find(asset => asset.id === assetId);
            if (assetDetails) {
                const roomDetails = state.rooms?.find(room => room.id === assetDetails.room_id);
                setRoomId(roomDetails?.id);
            }
        }
    }, [state.buildings,
        state.rooms, 
        state.assets, 
        organizationId, 
        buildingId, 
        roomId, 
        assetId]);

    const handleOrganizationChange = (value: string) => {
        const selectedOrganization = organizations.find((organization) => organization.id === value);
        setOrganization(selectedOrganization);
        // Update building, organization, and assets based on the selected room
        if (selectedOrganization) {
            const filteredBuildings = (state.buildings ?? []).filter((building) => building.organization_id === selectedOrganization.id);
            setBuildings(filteredBuildings);
            setBuilding(undefined);
            setRooms([]);
            setRoom(undefined);
            setAssets([]);

            setIsDisabledOrg(false);
            setIsOrgOpen(true);
        }
    };

    const handleBuildingChange = (value: string) => {
        const selectedBuilding = buildings.find((building) => building.id === value);
        setBuilding(selectedBuilding);
        // Update building, organization, and assets based on the selected room
        if (selectedBuilding) {
            const filteredRooms = (state.rooms ?? []).filter((room) => room.building_id === selectedBuilding.id);
            setRooms(filteredRooms);
            setRoom(undefined);
            setAssets([]);

            setIsDisabledBuilding(false);
            setIsDisabledRoom(true);
            setIsDisabledAsset(true);

            setIsBuildingOpen(true);
        }
    };

    const handleRoomChange = (value: string) => {
        const selectedRoom = rooms.find((room) => room.id === value);
        setRoom(selectedRoom);
        // Update building, organization, and assets based on the selected room
        if (selectedRoom) {
            const filteredAssets = (state.assets ?? []).filter((asset) => asset.room_id === selectedRoom?.id);
            setAssets(filteredAssets)

            const deviceDep = (state.deviceDeployments ?? []).filter((device, index, self) => {
                return index === self.findIndex((t) => (
                  t.device_id === device.device_id
                ));
              });
              setDevicedeployments(deviceDep);
          
              // Filter devices based on the unique device IDs in device deployments
              const uniqueDeviceIds = deviceDep.map((dep) => dep.device_id);
              const devicesInRoom = (state.devices ?? []).filter((device) => uniqueDeviceIds.includes(device.id));
              setDevices(devicesInRoom);

            console.log(deviceDep);
            console.log(devicesInRoom);
            setIsDisabledRoom(false);
            setIsDisabledAsset(false);

            setIsRoomOpen(true);
            setIsAssetOpen(true);
        }
    };

    function resetFilter(){
        setOrganization(undefined)
        setBuilding(undefined)
        setRoom(undefined)
        setAsset(undefined)
        setIsDisabledOrg(true)
        setIsDisabledBuilding(true)
        setIsDisabledRoom(true)
        setIsDisabledAsset(true)
        setIsOrgOpen(false)
        setIsBuildingOpen(false)
        setIsRoomOpen(false)
        setIsAssetOpen(false)
    }
    

    const empty = () => {
    };

    const routeToNewBuilding = () => {
        navigate(`/installation/building/?organizationId=${organization?.id}`);
    }
    const routeToEditBuilding = () => {
        navigate(`/installation/building/${building?.id}`);
    }
    const routeToNewRoom = () => {
        navigate(`/installation/room/?buildingId=${building?.id}`);
    }
    const routeToEditRoom = () => {
        navigate(`/installation/room/${room?.id}`);
    }
    const routeToNewAsset = () => {
        navigate(`/installation/device/?roomId=${room?.id}`);
    }
    const routeToEditAsset = (index: number) => {
        setAsset(assets[index])
        navigate(`/installation/device/assetId=${asset?.id}`);
    }

    return (
        <div className="tw-h-full">
            <div>
                <div>
                    <Titlebar headline="Overview" />
                </div>
                <main className="tw-relative tw-py-2 tw-mb-5 lg:tw-mx-10 tw-mx-3 tw-text-12 lg:tw-text-14">
                    <div className="tw-border-b tw-border-secondary-darker-off-white tw-pb-4 tw-border-dotted tw-mx-2">
                        <div className={"tw-flex-wrap tw-gap-3 lg:tw-mx-8 tw-flex tw-items-center tw-justify-center md:tw-justify-start tw-flex-col md:tw-flex-row tw-text-12 tw-mb-2 md:tw-mb-2 tw-pt-0.5"}>
                            <div>
                                <Button styles={"tw-max-h-[40px] tw-py-0"} type={"reset"} size={"medium"} variant={"secondary"} onClick={resetFilter}>Reset all filters</Button>
                            </div>
                            <h1 className={`tw-items-center tw-flex tw-justify-center tw-text-17 tw-pt-4 tw-font-bold`}>
                                Organization
                            </h1>
                            <GenericSelect
                                title=""
                                selected={organization}
                                setSelected={(organization: Organization) => handleOrganizationChange(organization?.id)}
                                mapAvailableToSelectable={function(o: Organization) {
                                    return {id: o.id, name: o.name}
                                }}
                                available={organizations}
                                border="yes"
                                className="tw-w-1/2 tw-pb-2"
                                selectedName="Select Organization"
                            />
                        </div>
                            {organization && (
                                <div className="tw-mx-8">
                                    <div className="">
                                        <table className="tw-w-full tw-text-left tw-border-2" id={organization?.id}>
                                            <tbody>
                                                <tr className="tw-border-b tw-border-t tw-bg-white hover:tw-bg-secondary-darker-off-white hover:tw-bg-opacity-30 tw-border-stroke-off-white">
                                                    <th
                                                        scope="col"
                                                        className="tw-pl-8 tw-pr-2 tw-py-2 tw-font-light tw-text-typography-muted tw-w-1/3">
                                                        Name
                                                    </th>
                                                    <td className="tw-pl-8 tw-pr-2 tw-py-2 tw-w-2/3">
                                                        {organization?.name}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="tw-border-b tw-border-secondary-darker-off-white tw-border-dotted tw-pb-4 tw-mx-2">
                            <h1 className={`tw-items-center tw-flex tw-justify-center tw-text-17 tw-pt-4 tw-font-bold`}>
                                Building
                            </h1>
                            <div className="tw-flex tw-w-full tw-px-2 tw-py-4">
                                <div className="tw-w-1/2 tw-flex tw-items-center tw-justify-center">
                                    <GenericSelect
                                        title=""
                                        selected={building}
                                        setSelected={(building: Building) => handleBuildingChange(building?.id)}
                                        mapAvailableToSelectable={function(b: Building) {
                                            return {id: b.id, name: b.name ?? b.address}
                                        }}
                                        available={buildings}
                                        disabled={organization === undefined}
                                        border="yes"
                                        className="tw-w-full tw-pr-2"
                                        selectedName="Select Building"
                                    />
                                </div>
                                <div className="tw-flex tw-items-center tw-justify-center">
                                    or
                                </div>
                                <div className="tw-w-1/2 tw-flex tw-items-center tw-justify-center tw-px-2">
                                    <Button
                                        styles="tw-w-full"
                                        type="button"
                                        size="small"
                                        variant={"third"}
                                        disabled={isDisabledOrg}
                                        onClick={routeToNewBuilding}
                                    >
                                        Add new building
                                    </Button>
                                </div>
                            </div>
                            {building && (
                                <div className="tw-mx-8">
                                    <div className="">
                                        <table className="tw-w-full tw-text-left tw-border-2" id={building?.id}>
                                            <tbody>
                                                <tr className="tw-border tw-border-t-2 tw-bg-white hover:tw-bg-secondary-darker-off-white hover:tw-bg-opacity-30 tw-border-stroke-off-white">
                                                    <th
                                                        scope="col"
                                                        className="tw-pl-8 tw-pr-2 tw-py-2 tw-font-light tw-text-typography-muted tw-w-1/3">
                                                        Name
                                                    </th>
                                                    <td className="tw-pl-8 tw-pr-2 tw-py-2 tw-w-2/3">
                                                        {building?.name}
                                                    </td>
                                                </tr>
                                                <tr className="tw-bg-white hover:tw-bg-secondary-darker-off-white hover:tw-bg-opacity-30">
                                                    <th
                                                        scope="col"
                                                        className="tw-pl-8 tw-pr-2 tw-py-2 tw-font-light tw-text-typography-muted tw-w-1/3">
                                                        Address
                                                    </th>
                                                    <td className="tw-pl-8 tw-pr-2 tw-py-2 tw-w-2/3">
                                                        {building?.address}
                                                    </td>
                                                </tr>
                                                <tr className="tw-border tw-bg-white hover:tw-bg-secondary-darker-off-white hover:tw-bg-opacity-30 tw-border-stroke-off-white">
                                                    <th
                                                        scope="col"
                                                        className="tw-pl-8 tw-pr-2 tw-py-2 tw-font-light tw-text-typography-muted tw-w-1/3">
                                                        Site
                                                    </th>
                                                    <td className="tw-pl-8 tw-pr-2 tw-py-2 tw-w-2/3">
                                                        {building?.site}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className={"tw-bg-white tw-border-b-2 tw-border-x-2 tw-flex-wrap tw-gap-1 lg:tw-mx-8 tw-flex tw-items-center tw-justify-center md:tw-justify-start tw-flex-col md:tw-flex-row tw-text-12 md:tw-mb-2 tw-py-2 lg:tw-pl-8"}>
                                            <Button styles={"tw-max-h-[40px] tw-w-40 tw-py-0 tw-w-1/10"} type={"button"} size={"small"} variant={"primary"} onClick={routeToEditBuilding}>Edit this building</Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="tw-border-b tw-border-secondary-darker-off-white tw-border-dotted tw-pb-4 tw-mx-2">
                            <h1 className={`tw-items-center tw-flex tw-justify-center tw-text-17 tw-pt-4 tw-font-bold`}>
                                Room
                            </h1>
                            <div className="tw-flex tw-w-full tw-px-2 tw-py-4">
                                <div className="tw-w-1/2 tw-flex tw-items-center tw-justify-center">
                                    <GenericSelect
                                        title=""
                                        selected={room}
                                        setSelected={(room: Room) => handleRoomChange(room?.id)}
                                        mapAvailableToSelectable={function(r: Room) {
                                            return {id: r.id, name: r.name ?? r.nice_name}
                                        }}
                                        available={rooms}
                                        disabled={building === undefined}
                                        border="yes"
                                        className="tw-w-full tw-pr-2"
                                        selectedName="Select Room"
                                    />
                                </div>
                                <div className="tw-flex tw-items-center tw-justify-center">
                                    or
                                </div>
                                <div className="tw-w-1/2 tw-flex tw-items-center tw-justify-center tw-px-2">
                                    <Button 
                                    styles="tw-w-full"
                                     type="button"
                                     size="small"
                                     variant="third"
                                     disabled={isDisabledBuilding} 
                                     onClick={routeToNewRoom}
                                     >
                                        Add new room
                                    </Button>

                                </div>
                            </div>
                            {room && (
                                <div className="tw-mx-8">
                                    <div className="">
                                        <table className="tw-w-full tw-text-left tw-border-2" id={room?.id}>
                                            <tbody>
                                                <tr className="tw-border-b tw-border-t tw-bg-white hover:tw-bg-secondary-darker-off-white hover:tw-bg-opacity-30 tw-border-stroke-off-white">
                                                    <th
                                                        scope="col"
                                                        className="tw-pl-8 tw-pr-2 tw-py-2 tw-font-light tw-text-typography-muted tw-w-1/3">
                                                        Name
                                                    </th>
                                                    <td className="tw-pl-8 tw-pr-2 tw-py-2 tw-w-2/3">
                                                        {room?.nice_name ?? room.name}
                                                    </td>
                                                </tr>
                                                <tr className="tw-border-b tw-border-t tw-bg-white hover:tw-bg-secondary-darker-off-white hover:tw-bg-opacity-30 tw-border-stroke-off-white">
                                                    <th
                                                        scope="col"
                                                        className="tw-pl-8 tw-pr-2 tw-py-2 tw-font-light tw-text-typography-muted tw-w-1/3">
                                                        Floor
                                                    </th>
                                                    <td className="tw-pl-8 tw-pr-2 tw-py-2 tw-w-2/3">
                                                        {room?.floor}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className={"tw-bg-white tw-border-b-2 tw-border-x-2 tw-flex-wrap lg:tw-mx-8 tw-flex tw-items-center tw-justify-center md:tw-justify-start tw-flex-col md:tw-flex-row tw-text-12 md:tw-mb-2 tw-py-2 lg:tw-pl-8"}>
                                            <Button styles={"tw-max-h-[40px] tw-w-40 tw-py-0"} type={"button"} size={"small"} variant={"primary"} onClick={routeToEditRoom}>Edit this room</Button> 
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="tw-border-b tw-border-secondary-darker-off-white tw-border-dotted tw-pb-4 tw-mx-2">
                            <div className={"tw-flex-wrap tw-gap-3 lg:tw-mx-8 tw-flex tw-items-center tw-justify-center md:tw-justify-start tw-flex-col md:tw-flex-row tw-text-12 tw-mb-2 md:tw-mb-2 tw-pt-0.5"}>
                                <h1 className={`tw-items-center tw-flex tw-justify-center tw-text-17 tw-pt-4 tw-font-bold`}>
                                    Devices
                                </h1>
                                <div className={"tw-flex-wrap tw-gap-1 lg:tw-mx-8 tw-flex tw-items-center tw-justify-center md:tw-justify-start tw-flex-col md:tw-flex-row tw-text-12 tw-mb-2 md:tw-mb-2 lg:tw-pl-8"}>
                                    <Button styles={"tw-max-h-[40px] tw-w-40 tw-py-0"} type={"button"} size={"small"} variant={"primary"} disabled={isDisabledRoom} onClick={routeToNewAsset}>Add new device</Button> 
                                </div>
                            </div>
                                {room && (
                                <div className="tw-mx-8">
                                    <div className="">
                                            {devices
                                                .map((device, deviceIndex) => (
                                                    <div className="tw-pb-2">
                                                        <h1 className={`tw-items-center tw-flex tw-justify-center tw-text-12 tw-py-1 tw-font-bold tw-text-typography-muted tw-bg-white tw-border-x-2 tw-border-t-2`}>
                                                            Device
                                                        </h1>
                                                        <table className="tw-w-full tw-text-left tw-border-2" id={device?.id} key={deviceIndex}>
                                                            <tbody>
                                                                <tr className="tw-border-b tw-border-t tw-bg-white hover:tw-bg-secondary-darker-off-white hover:tw-bg-opacity-30 tw-border-stroke-off-white">
                                                                    <th
                                                                        scope="col"
                                                                        className="tw-pl-6 tw-pr-2 tw-py-2 tw-font-light tw-text-typography-muted tw-w-1/3">
                                                                        Model
                                                                    </th>
                                                                    <td className="tw-pl-7 tw-pr-2 tw-py-2 tw-w-2/3">
                                                                        {device.type?.model ?? device.type?.brand}
                                                                    </td>
                                                                </tr>
                                                                <tr className="tw-border-b tw-border-t tw-bg-white hover:tw-bg-secondary-darker-off-white hover:tw-bg-opacity-30 tw-border-stroke-off-white">
                                                                    <th
                                                                        scope="col"
                                                                        className="tw-pl-6 tw-pr-2 tw-py-2 tw-font-light tw-text-typography-muted tw-w-1/3">
                                                                        Serial id
                                                                    </th>
                                                                    <td className="tw-pl-7 tw-pr-2 tw-py-2 tw-w-2/3">
                                                                        {device.serial}
                                                                    </td>
                                                                </tr>
                                                                {devicedeployments
                                                                    .filter((deviceD) => deviceD.device_id = device.id)
                                                                    .map((deviceD, deviceDIndex) => (
                                                                <tr className="tw-border-b tw-border-t tw-bg-white hover:tw-bg-secondary-darker-off-white hover:tw-bg-opacity-30 tw-border-stroke-off-white" key={deviceDIndex}>
                                                                    <th
                                                                        scope="col"
                                                                        className="tw-pl-6 tw-pr-2 tw-py-2 tw-font-light tw-text-typography-muted tw-w-1/3">
                                                                        Mounted on asset:
                                                                    </th>
                                                                    <td className="tw-pl-7 tw-pr-2 tw-py-2 tw-w-2/3">
                                                                        {deviceD.assets[0].name}
                                                                    </td>
                                                                </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                        <div className={"tw-flex-wrap tw-gap-1 tw-bg-white lg:tw-mx-8 tw-flex tw-items-center tw-justify-center md:tw-justify-start tw-flex-col md:tw-flex-row tw-text-12 md:tw-mb-2 tw-py-2 lg:tw-pl-8 tw-border-x-2 tw-border-b-2"}>
                                                            <Button styles={"tw-max-h-[40px] tw-w-40 tw-py-0"} type={"button"} size={"small"} variant={"primary"} onClick={routeToEditAsset}>Edit this device</Button> 
                                                        </div>
                                                    </div>
                                            ))}
                                    </div>
                                </div>
                                )}
                        </div>
                </main>
            </div>
        </div>
    )
    }  